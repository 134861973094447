import { Button } from "antd";
import React, { FC } from "react";
import bugsnag from "@bugsnag/js";
import { BugsnagPluginReactResult } from "@bugsnag/plugin-react";
import { config } from "../config";
import { ReloadOutlined } from "@ant-design/icons";

const bugsnagConfig = {
  apiKey: config.bugsnag.apiKey,
  notifyReleaseStages: ["production"],
};

export const bugsnagClient = bugsnag;
bugsnagClient.start(bugsnagConfig);
const plugin = bugsnagClient.getPlugin("react") as BugsnagPluginReactResult;
export const BugsnagErrorBoundary = plugin && plugin.createErrorBoundary();

const FallbackComponent: FC<{ error: any; info: any }> = props => {
  const stringError = String(props.error);

  return (
    <div style={{ maxWidth: "100vw", maxHeight: "100vh", overflow: "hidden" }}>
      <h1>{stringError || "Unknown error"}</h1>
      <Button
        icon={<ReloadOutlined />}
        type={"primary"}
        onClick={() => location.reload()}
      >
        Reload the page
      </Button>
    </div>
  );
};
export const ErrorBoundary: FC = props => {
  return (
    <>
      {BugsnagErrorBoundary ? (
        <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
          {props.children}
        </BugsnagErrorBoundary>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};
