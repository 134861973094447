import { ProductType } from "../bundles/types";
import { IServiceWithLocation } from "../../../entities/services/types";
import { GearType, GearTypeMc } from "../../../entities/vehicles/types";
import { IApiError, IList } from "src/api/types";

export enum LanguageKeys {
  English = "en",
  Swedish = "sv",
}
export interface IClientData {
  id: number;
  name: string;
  phone: string;
  ssn: string;
  authId: string;
  email: string;
  permitExpDate?: string | null;
  options: {
    hideForPermitList?: boolean;
    defaultLanguage?: LanguageKeys;
    vehicleGearType?: GearType;
    vehicleGearTypeMc?: GearTypeMc;
    mopedEligibilityReportDate?: string;
  };
  currentLocationId?: number;
}

export interface ICreateClientRequestPayload
  extends Pick<
    IClientData,
    "name" | "phone" | "ssn" | "email" | "currentLocationId"
  > {}

export interface IEditClientPayload {
  id: number;
  values: Partial<
    Pick<
      IClientData,
      keyof ICreateClientRequestPayload | "permitExpDate" | "options"
    >
  >;
}

export interface IClientProfile {
  balance: number;
  currentLocationId: number | null;
  isAutomaticCar: boolean;
  availableRegions: number[];
  availableLocations: number[];
}

export interface IClientBookingConfig {
  serviceId: number;
}

export interface IClientsFilter {
  name?: string;
  ssn?: string;
  phone?: string;
  email?: string;
  limit?: number;
  offset?: number;
  authId?: string;
  hideForPermitList?: string;
  instructorsIds?: number[];
  locationId?: number;
  createdAt?: IFilterRangedField;
  search?: string;
}
export interface IFilterRangedField {
  from: string | null;
  to?: string | null;
}

export interface IClientData {
  id: number;
  name: string;
}

export interface ISaldo {
  id: number;
  type: ProductType;
  name: string;
  qty: number;
}

export interface ISaldoItem {
  id: number;
  type: ProductType;
  name: string;
  qty: number;
  service: IServiceWithLocation;
}

export interface IPermitData extends IList<IClientData> {
  clientsBookings: {
    [clientId: number]: { bookingId: number; startAt: number };
  };
  result: IClientData[];
  total: number;
}

export interface IPermitResponse extends IApiError {
  data: IPermitData;
}
