import * as moment from "moment";
import "moment/locale/en-gb";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { setConfig } from "react-hot-loader";
import App from "./components/App";
import { ErrorBoundary } from "./components/ErrorBoundary";
import "./styles/index.global.css";
import "./styles/variables.antd.less";
moment.locale("en-gb"); // Week starts from Monday
import "./i18n/i18n";

setConfig({
  reloadHooks: false,
});

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);
