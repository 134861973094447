import { Left } from "fp-ts/lib/Either";
import { Errors } from "io-ts";
import { reporter } from "io-ts-reporters";
import { PathReporter } from "io-ts/es6/PathReporter";

export function collectErrors(result: Left<Errors>): string[] {
  return reporter(result);
}

export function toError(result: Left<Errors>): Error {
  return new Error(PathReporter.report(result).join("\n"));
}
