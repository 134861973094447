export interface IApiError {
  errorCode: number;
  errorMessage?: string;
}

export interface IApiResponse<T> extends IApiError {
  data: T;
}

export interface IListApiResponse<T> extends IApiError {
  data: IList<T>;
}

export interface IList<T> {
  total: number;
  result: T[];
}

// FIXME: move all next
export interface ITranslatedField {
  en: string;
  sw: string;
}

export enum BinaryAsBooleanValues {
  True = "1",
  False = "0",
}
export interface IFilterRangedField {
  from: string;
  to?: string;
}
