import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enLocales from "./locales/en/translation.json";
import svLocales from "./locales/sv/translation.json";
import { handleLocaleChanges } from "../instructorUI/components/Layout/LanguageSelector";

export enum Language {
  English = "en",
  Swedish = "sv",
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: {
      default: [Language.English],
      en: [Language.English],
      sv: [Language.Swedish],
    },
    defaultNS: "translation",
    detection: {
      order: ["localStorage", "cookie"],
      caches: ["localStorage", "cookie"],
      lookupLocalStorage: "i18nextLng",
    },
    ns: ["translation"],
    resources: {
      [Language.English]: {
        translation: enLocales,
      },
      [Language.Swedish]: {
        translation: svLocales,
      },
    },
  })
  .then(() => handleLocaleChanges(i18n.language))
  .catch(err => console.error(err));

export default i18n;
