import { Spin } from "antd";
import { FC } from "react";
import * as React from "react";
import { hot } from "react-hot-loader/root";
import { Route, Router, Switch } from "react-router";
import { config } from "../config";
import { lazyChunkFactory } from "../helpers/lazyLoad";
import { useHistory } from "../hooks";
import { FirebaseAuthProvider } from "../modules/firebase/auth/provider";
import {
  FirebaseDatabaseProvider,
  useFirebaseDatabase,
} from "../modules/firebase/database/provider";
import { FirebaseFunctionsProvider } from "../modules/firebase/functions/provider";
import { FirebaseStorageProvider } from "../modules/firebase/storage/provider";
import { FirebaseProvider } from "../modules/firebase/provider";
import {
  SessionsModuleProvider,
  SessionsProfileProvider,
} from "../modules/SessionModule/context";
import { SettingsProvider } from "../modules/settings/context";
import { BookingsServiceProvider } from "../services/BookingsService/context";
import { UsersServiceProvider } from "../services/UsersService/context";
import { SchoolsServiceProvider } from "../services/SchoolsService/context";
import { ServicesServiceProvider } from "../services/ServicesService/context";
import { ChatModuleProvider as BaseChatModuleProvider } from "@enna/chat/dist";
import { QueryClient, QueryClientProvider } from "react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ChatModuleProvider: FC = props => {
  const database = useFirebaseDatabase();
  return (
    <BaseChatModuleProvider database={database}>
      {props.children}
    </BaseChatModuleProvider>
  );
};
const InstructorApp = React.lazy(
  lazyChunkFactory(() =>
    import(
      /*  webpackChunkName: "instructorUI" */ "../instructorUI/components/App"
    )
  )
);

function AppView(): JSX.Element {
  const history = useHistory();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        notifyOnChangeProps: "tracked",
        staleTime: 60 * 1000 * 10,
        refetchOnMount: false,
        retry: false,
        // onError: error =>
        //   console.error(`Something went wrong: ${error.message}`),
      },
    },
    // queryCache: new QueryCache({
    //   onError: (error: any) => {
    //     console.error(`Something went wrong: ${error}`);
    //   },
    // }),
  });
  return (
    <DndProvider backend={HTML5Backend}>
      <FirebaseProvider>
        <FirebaseFunctionsProvider>
          <FirebaseAuthProvider>
            <FirebaseDatabaseProvider>
              <FirebaseStorageProvider>
                <QueryClientProvider client={queryClient}>
                  <SettingsProvider>
                    <SchoolsServiceProvider>
                      <UsersServiceProvider>
                        <BookingsServiceProvider>
                          <ServicesServiceProvider>
                            <SessionsModuleProvider>
                              <SessionsProfileProvider>
                                <ChatModuleProvider>
                                  <Router history={history}>
                                    <React.Suspense fallback={<Spin />}>
                                      <Switch>
                                        <Route
                                          path={config.instructorui.urlPrefix}
                                          component={InstructorApp}
                                        />
                                      </Switch>
                                    </React.Suspense>
                                  </Router>
                                </ChatModuleProvider>
                              </SessionsProfileProvider>
                            </SessionsModuleProvider>
                          </ServicesServiceProvider>
                        </BookingsServiceProvider>
                      </UsersServiceProvider>
                    </SchoolsServiceProvider>
                  </SettingsProvider>
                </QueryClientProvider>
              </FirebaseStorageProvider>
            </FirebaseDatabaseProvider>
          </FirebaseAuthProvider>
        </FirebaseFunctionsProvider>
      </FirebaseProvider>
    </DndProvider>
  );
}
export default hot(AppView);
