import * as firebase from "firebase/app";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { dictFromUnsafeValue } from "../../helpers/firebase";
import {
  IMaintenanceList,
  IRawSettings,
  ISafeLocationGroup,
  ISafeSettings,
  ISafeSettingsEvent,
  ISafeSettingsLocation,
  ISafeSettingsLocationEvents,
  ISafeSettingsUnit,
  ISettings,
  ISettingsEvent,
  ITranslations,
  IUnsafeSettingsEvent,
  IUnsafeSettingsLocation,
  IUnsafeSettingsUnit,
} from "./types";

export class Settings implements ISettings {
  public readonly unitsByLocations$: Observable<
    ReadonlyMap<ISafeSettingsLocation, readonly ISafeSettingsUnit[]>
  >;
  public readonly settings$: Observable<ISafeSettings>;
  public readonly locations$: Observable<readonly ISafeSettingsLocation[]>;
  public readonly events$: Observable<readonly ISettingsEvent[]>;
  protected readonly database: firebase.database.Database;
  constructor(database: firebase.database.Database) {
    this.database = database;
    const rawSettings$ = getRawSettings$(database).pipe(shareReplay(1));
    this.settings$ = getSafeSettings$(rawSettings$).pipe(shareReplay(1));
    this.unitsByLocations$ = getUnitsByLocations$(this.settings$).pipe(
      shareReplay(1)
    );
    this.locations$ = getLocations$(this.settings$).pipe(shareReplay(1));
    this.events$ = this.locations$.pipe(
      map(locations => {
        const events = new Map<number, ISettingsEvent>();
        for (const location of locations) {
          for (const event of location.events) {
            const sEvent = events.get(event.id) || {
              availableUnitsIds: {},
              defaultLessonsQty: event.defaultLessonsQty,
              duration: event.duration, // min
              id: event.id,
              isClass: event.isClass,
              description: event.description,
              isActive: event.isActive,
              isPublic: event.isPublic,
              isVisible: event.isVisible,
              key: event.key,
              lessonQty: event.lessonQty,
              name: event.name,
              picturePath: event.picturePath,
              price: event.price,
              priceWithTax: event.priceWithTax,
              position: event.position,
            };

            sEvent.availableUnitsIds[location.id] = event.availableUnitsIds;

            events.set(event.id, sEvent);
          }
        }

        return Array.from(events.values());
      }),
      shareReplay({
        bufferSize: 1,
        refCount: false,
      })
    );
  }
}

const settingsPath = "/v3/settings";

function getLocations$(
  settings$: Observable<ISafeSettings>
): Observable<readonly ISafeSettingsLocation[]> {
  return settings$.pipe(
    map(_ => {
      return _.locationEvents.locations
        .slice()
        .sort((a, b) => a.position - b.position);
    })
  );
}

function getRawSettings$(
  database: firebase.database.Database
): Observable<IRawSettings | undefined> {
  return new Observable<IRawSettings | undefined>(subscriber => {
    const onSettingsChanged = (
      snapshot: firebase.database.DataSnapshot | null
    ): void => {
      const nextVal = snapshot ? (snapshot.val() as IRawSettings) : undefined;
      subscriber.next(nextVal);
    };
    const ref = database.ref(settingsPath);

    ref.on("value", onSettingsChanged);

    return () => {
      ref.off("value", onSettingsChanged);
    };
  });
}

function getSafeSettings$(
  rawSettings$: Observable<IRawSettings | undefined>
): Observable<ISafeSettings> {
  return rawSettings$.pipe(map(_ => toSafeSettings(_)));
}

function toSafeSettingsUnit(x: IUnsafeSettingsUnit): ISafeSettingsUnit {
  const dict = dictFromUnsafeValue(x.positionInLocation);
  const positionInLocation = Object.keys(dict).reduce<{
    [key: string]: number;
  }>((p, locationId) => {
    p[locationId] = Number(dict[locationId]);
    return p;
  }, {});
  return {
    classId: x.classId ? Number(x.classId) : undefined,
    description: x.description,
    email: x.email,
    id: Number(x.id),
    isActive: x.isActive,
    isAutomaticCar: x.isAutomaticCar,
    isVisible: x.isVisible,
    name: x.name,
    phone: x.phone,
    picturePath: x.picturePath,
    positionInLocation,
    stationId: Number(x.stationId),
    qty: x.qty,
  };
}

function getUnitsByLocations$(
  settings$: Observable<ISafeSettings>
): Observable<
  ReadonlyMap<ISafeSettingsLocation, readonly ISafeSettingsUnit[]>
> {
  return settings$.pipe(
    map(settings => {
      const unitsMap = new Map<
        ISafeSettingsLocation,
        readonly ISafeSettingsUnit[]
      >();
      const sortedLocations = settings.locationEvents.locations
        .slice()
        .sort((a, b) => a.position - b.position);
      const unitsDict = settings.units.reduce<{
        [key: string]: ISafeSettingsUnit;
      }>((dict, unit) => {
        dict[unit.id] = unit;
        return dict;
      }, {});
      for (const location of sortedLocations) {
        const chunk: ISafeSettingsUnit[] = [];
        unitsMap.set(location, chunk);
        const locationUnits = location.availableUnitsIds
          .map<ISafeSettingsUnit>(unitId => unitsDict[unitId])
          .filter(_ => !!_)
          .sort((a, b) => {
            const posA = a.positionInLocation[location.id] || 0;
            const posB = b.positionInLocation[location.id] || 0;
            return posA - posB;
          });
        for (const unit of locationUnits) {
          if (unit) {
            chunk.push(unit);
          }
        }
      }
      return unitsMap;
    })
  );
}

function toSafeSettings(rawSettings?: IRawSettings): ISafeSettings {
  if (!rawSettings) {
    return {
      drivingPlanMeta: "",
      locationEvents: { locations: [] },
      locationGroups: [],
      maintenanceList: {},
      translations: { en: {}, sv: {} },
      units: [],
    };
  }
  const maintenanceList: IMaintenanceList = rawSettings.maintenanceList || {};
  const translations: ITranslations = rawSettings.translations;
  const drivingPlanMeta: string = rawSettings.drivingPlanMeta;
  const locationGroups: ISafeLocationGroup[] = Object.entries(
    rawSettings.locationsGroups
  )
    .filter(([, group]) => group.available)
    .map(([key, group]) => {
      const safeGroup: ISafeLocationGroup = {
        key,
        locationIds: toArray(dictFromUnsafeValue(group.locationsIds)).map(x =>
          Number(x)
        ),
      };
      return safeGroup;
    });

  const units = toArray(dictFromUnsafeValue(rawSettings.units)).map(rawUnit =>
    toSafeSettingsUnit(rawUnit)
  );
  const locationEvents: ISafeSettingsLocationEvents = {
    locations: toArray(
      dictFromUnsafeValue(rawSettings.locationEvents.locations)
    ).map(rawLocation => toSafeSettingsLocation(rawLocation)),
  };
  return {
    drivingPlanMeta,
    locationEvents,
    locationGroups,
    maintenanceList,
    translations,
    units,
  };
}

function toSafeSettingsEvent(
  rawEvent: IUnsafeSettingsEvent
): ISafeSettingsEvent {
  return {
    availableUnitsIds: toArray(
      dictFromUnsafeValue(rawEvent.availableUnitsIds)
    ).map(unitId => Number(unitId)),
    defaultLessonsQty: Number(rawEvent.defaultLessonsQty),
    description: rawEvent.description,
    duration: Number(rawEvent.duration), // min
    id: Number(rawEvent.id),
    isActive: rawEvent.isActive,
    isClass: rawEvent.isClass,
    isPublic: rawEvent.isPublic,
    isVisible: rawEvent.isVisible,
    key: rawEvent.key,
    lessonQty: Number(rawEvent.lessonQty),
    name: rawEvent.name,
    picturePath: rawEvent.picturePath,
    position: Number(rawEvent.position),
    price: Number(rawEvent.price),
    priceWithTax: Number(rawEvent.priceWithTax),
  };
}
function toSafeSettingsLocation(
  rawLocation: IUnsafeSettingsLocation
): ISafeSettingsLocation {
  const events: readonly ISafeSettingsEvent[] = toArray(
    dictFromUnsafeValue(rawLocation.events)
  ).map(event => toSafeSettingsEvent(event));
  return {
    address1: rawLocation.address1,
    address2: rawLocation.address2,
    availableUnitsIds: toArray(
      dictFromUnsafeValue(rawLocation.availableUnitsIds)
    ).map(y => Number(y)),
    city: rawLocation.city,
    description: rawLocation.description,
    events,
    eventsOrder: rawLocation.eventsOrder
      ? rawLocation.eventsOrder.map(y => Number(y))
      : [],
    id: Number(rawLocation.id),
    lat: Number(rawLocation.lat),
    lng: Number(rawLocation.lng),
    name: rawLocation.name,
    position: Number(rawLocation.position),
    zip: rawLocation.zip,
  };
}

function toArray<T>(val: { [key: string]: T }): T[] {
  return Object.keys(val)
    .map(key => val[key])
    .filter(x => !!x);
}
