import * as firebase from "firebase";
import * as t from "io-ts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IConfig } from "../../config/io-ts";
import { makeGetRequest$ } from "../../helpers/http";
import {
  IApiClassScheduleV3,
  IServiceV3,
  ApiClassScheduleV3,
  ServiceV3,
} from "./io-ts";
import { IServicesService } from "./types";
import { IServicesSearch } from "../../pages/Services/context/types";
import qs from "qs";
import { toDateStr } from "../../helpers/datetime";

export class ServicesService implements IServicesService {
  protected readonly fbAuth: firebase.auth.Auth;
  protected readonly config: IConfig;
  protected readonly fbDatabase: firebase.database.Database;
  constructor(
    fbAuth: firebase.auth.Auth,
    config: IConfig,
    fbDatabase: firebase.database.Database
  ) {
    this.fbAuth = fbAuth;
    this.config = config;
    this.fbDatabase = fbDatabase;
  }
  public fetchServicesV3(payload?: IServicesSearch): Observable<IServiceV3[]> {
    const query = qs.stringify(payload);
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/services/search?${query &&
      query}`;

    return makeGetRequest$<IServiceV3[]>(
      endpoint,
      {},
      t.array(t.exact(ServiceV3)),
      this.fbAuth,
      false,
      true
    ).pipe(map(_ => _));
  }

  public getClassesScheduleV3(
    from: Date,
    to: Date,
    regionsIds?: number[]
  ): Observable<IApiClassScheduleV3> {
    const endpoint = `${
      this.config.mdaCoreApiUrl
    }/v3/services/classes/schedule/from/${toDateStr(from)}/to/${toDateStr(
      to
    )}/${(regionsIds?.length && "regionsIds/" + regionsIds) || ""}`;

    return makeGetRequest$<IApiClassScheduleV3>(
      endpoint,
      {},
      ApiClassScheduleV3,
      this.fbAuth
    ).pipe(map(_ => _));
  }
}
