import "firebase/storage";
import React from "react";
import { FC, useContext } from "react";
import { bindUseRequiredContext, useComputed } from "../../../hooks";
import { useFirebase } from "../provider";
import { FirebaseStorageContext } from "./context";

export const FirebaseStorageProvider: FC = props => {
  const upperDefinedFirebase = useContext(FirebaseStorageContext);
  if (upperDefinedFirebase) {
    throw new Error(
      "Currently we supports only one FirebaseStorageContext in the tree"
    );
  }
  const firebaseApp = useFirebase();
  const storage = useComputed(() => firebaseApp.storage(), [firebaseApp]);

  return (
    <FirebaseStorageContext.Provider value={storage}>
      {props.children}
    </FirebaseStorageContext.Provider>
  );
};
export const useFirebaseStorage = bindUseRequiredContext(
  FirebaseStorageContext
);
