import "firebase/functions";
import React from "react";
import { FC, useContext } from "react";
import { bindUseRequiredContext, useComputed } from "../../../hooks";
import { useFirebase } from "../provider";
import { FirebaseFunctionsContext } from "./context";

export const FirebaseFunctionsProvider: FC = props => {
  const upperDefinedFirebase = useContext(FirebaseFunctionsContext);
  if (upperDefinedFirebase) {
    throw new Error(
      "Currently we supports only one FirebaseFunctionsProvider in the tree"
    );
  }
  const firebaseApp = useFirebase();
  const functions = useComputed(() => firebaseApp.functions(), [firebaseApp]);

  return (
    <FirebaseFunctionsContext.Provider value={functions}>
      {props.children}
    </FirebaseFunctionsContext.Provider>
  );
};

export const useFirebaseFunctions = bindUseRequiredContext(
  FirebaseFunctionsContext
);
