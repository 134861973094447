import React, { createContext, FC } from "react";
import { config } from "../../config";
import { bindUseRequiredContext, useComputed } from "../../hooks";
import { useFirebaseAuth } from "../../modules/firebase/auth/provider";
import { useFirebaseDatabase } from "../../modules/firebase/database/provider";
import { ServicesService } from "./";
import { IServicesService } from "./types";

export const ServicesServiceContext = createContext<
  IServicesService | undefined
>(undefined);
ServicesServiceContext.displayName = "ServicesServiceContext";

export const ServicesServiceProvider: FC = props => {
  const fbAuth = useFirebaseAuth();
  const fbDatabase = useFirebaseDatabase();
  const servicesService = useComputed(
    () => new ServicesService(fbAuth, config, fbDatabase),
    [fbAuth, fbDatabase]
  );

  return (
    <ServicesServiceContext.Provider value={servicesService}>
      {props.children}
    </ServicesServiceContext.Provider>
  );
};

export const useServicesService = bindUseRequiredContext(
  ServicesServiceContext
);
