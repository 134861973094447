import { createBrowserHistory, History } from "history";
import {
  Context,
  DependencyList,
  useContext,
  useDebugValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { concat, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export function useRequiredContext<T>(context: Context<T | undefined>): T {
  const dependency = useContext(context);
  useDebugValue(dependency);
  if (!dependency) {
    throw new Error(
      `Dependency (${context.displayName || "unnamed"}) is required`
    );
  }

  return dependency;
}
export function bindUseRequiredContext<T>(
  context: Context<T | undefined>
): () => T {
  const hook: (c: typeof context) => T = useRequiredContext;
  return hook.bind(undefined, context);
}

export const history = createBrowserHistory();
export function useHistory(): History {
  return history;
}

function isDepsEqual<D extends DependencyList>(d1: D, d2: D): boolean {
  if (d1.length !== d2.length) {
    return false;
  }
  for (let i = 0; i < d1.length; ++i) {
    if (d1[i] !== d2[i]) {
      return false;
    }
  }

  return true;
}

const UndefinedSymbol: unique symbol = Symbol();
/**
 * Like useMemo but it is as stable as useState.
 * Useful in case of creating view-entities.
 * They have dynamic dependencies (which means we want to use something like useMemo)
 * but they have internal state, so unpredictable useMemo does  not fill our requirements
 */
export function useComputed<S, D extends DependencyList>(
  factory: () => S,
  deps: D
): S {
  const key = useRef<D>(deps);
  const depsEqual = isDepsEqual(key.current, deps);
  const s = useRef<S | typeof UndefinedSymbol>(UndefinedSymbol);
  if (depsEqual) {
    if (s.current === UndefinedSymbol) {
      s.current = factory();
    }
  } else {
    s.current = factory();
  }

  key.current = deps;
  useDebugValue(s.current);

  return s.current;
}

export function useObservable<T, I = T>(
  observable: Observable<T>,
  initialValue: I
): T | I {
  const [value, setValue] = useState<T | I>(initialValue);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    const subscription = observable.subscribe({
      error: setError,
      next: setValue,
    });

    return function unsubscribe() {
      subscription.unsubscribe();
    };
  }, [observable]);
  if (error) {
    throw error;
  }
  return value;
}

function useMemoGuard<T>(value: T): void {
  if (process.env.NODE_ENV === "development") {
    /* eslint-disable react-hooks/rules-of-hooks */
    const prevInitialValue = useRef(value);
    const diffInRow = useRef(0);
    if (value !== prevInitialValue.current) {
      prevInitialValue.current = value;
      diffInRow.current += 1;
      if (diffInRow.current > 10) {
        console.error(
          "Too much re-renders with different initialValue in row. \n" +
            "Probable you used complex initial value and should wrap it with useMemo before passing to useLoadableObservable"
        );
        throw new Error(
          "Too much re-renders with different initialValue in row."
        );
      }
    } else {
      diffInRow.current += 0;
    }
    /* eslint-enable react-hooks/rules-of-hooks */
  }
}
export function useLoadableObservable<T>(
  observable: Observable<T>,
  initialValue: T
): { loading: boolean; data: T } {
  useMemoGuard(initialValue);
  const o$ = useMemo(
    () =>
      concat(
        of({ loading: true, data: initialValue }),
        observable.pipe(map(data => ({ loading: false, data })))
      ),
    [initialValue, observable]
  );

  const { loading, data } = useObservable(o$, {
    loading: true,
    data: initialValue,
  });

  return { loading, data };
}
