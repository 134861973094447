import { createContext, default as React, FC, useMemo } from "react";
import {
  bindUseRequiredContext,
  useComputed,
  useLoadableObservable,
} from "../../hooks";
import { useSchoolsService } from "../../services/SchoolsService/context";
import { useUsersService } from "../../services/UsersService/context";
import { useFirebaseAuth } from "../firebase/auth/provider";
import { SessionModule } from "./index";
import { IProfile, ISessionModule } from "./types";

export const SessionModuleContext = createContext<ISessionModule | undefined>(
  undefined
);
SessionModuleContext.displayName = "SessionModuleContext";

export const SessionsModuleProvider: FC = props => {
  const fbAuth = useFirebaseAuth();
  const usersService = useUsersService();
  const schoolsService = useSchoolsService();
  const sessionModule = useComputed(
    () => new SessionModule(fbAuth, usersService, schoolsService),
    [fbAuth, usersService]
  );

  return (
    <SessionModuleContext.Provider value={sessionModule}>
      {props.children}
    </SessionModuleContext.Provider>
  );
};

export const useSessionModule = bindUseRequiredContext(SessionModuleContext);

export const SessionProfileContext = createContext<
  | {
      loading: boolean;
      profile: IProfile | null;
    }
  | undefined
>(undefined);
SessionProfileContext.displayName = "SessionProfileContext";

export const SessionsProfileProvider: FC = props => {
  const session = useSessionModule();
  const { loading, data: profile } = useLoadableObservable(
    session.profile$,
    null
  );
  const value = useMemo(() => ({ loading, profile }), [loading, profile]);
  return (
    <SessionProfileContext.Provider value={value}>
      {props.children}
    </SessionProfileContext.Provider>
  );
};

export const useProfile = bindUseRequiredContext(SessionProfileContext);
