import "firebase/database";
import React from "react";
import { FC, useContext } from "react";
import { bindUseRequiredContext, useComputed } from "../../../hooks";
import { useFirebase } from "../provider";
import { FirebaseDatabaseContext } from "./context";

export const FirebaseDatabaseProvider: FC = props => {
  const upperDefinedFirebase = useContext(FirebaseDatabaseContext);
  if (upperDefinedFirebase) {
    throw new Error(
      "Currently we supports only one FirebaseDatabaseContext in the tree"
    );
  }
  const firebaseApp = useFirebase();
  const database = useComputed(() => firebaseApp.database(), [firebaseApp]);

  return (
    <FirebaseDatabaseContext.Provider value={database}>
      {props.children}
    </FirebaseDatabaseContext.Provider>
  );
};
export const useFirebaseDatabase = bindUseRequiredContext(
  FirebaseDatabaseContext
);
