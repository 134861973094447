import * as firebase from "firebase/app";
import { FC, useContext, useEffect, useState } from "react";
import * as React from "react";
import { config } from "../../config";
import { bindUseRequiredContext } from "../../hooks";
import { FirebaseAppContext } from "./context";

export const FirebaseProvider: FC = props => {
  const upperDefinedFirebase = useContext(FirebaseAppContext);
  if (upperDefinedFirebase) {
    throw new Error(
      "Currently we supports only one FirebaseProvider in the tree"
    );
  }
  const [error, setError] = useState<unknown | undefined>();
  const {
    firebase: {
      appId,
      apiKey,
      authDomain,
      databaseURL,
      projectId,
      storageBucket,
      messagingSenderId,
    },
  } = config;
  const [firebaseApp] = useState(() => {
    return firebase.initializeApp({
      appId,
      apiKey,
      authDomain,
      databaseURL,
      projectId,
      storageBucket,
      messagingSenderId,
    });
  });

  useEffect(() => {
    return function unmount() {
      firebaseApp.delete().catch(err => {
        setError(err);
      });
    };
  }, [firebaseApp]);
  if (error) {
    throw error;
  }
  return (
    <FirebaseAppContext.Provider value={firebaseApp}>
      {props.children}
    </FirebaseAppContext.Provider>
  );
};

export const useFirebase = bindUseRequiredContext(FirebaseAppContext);
