import React, { createContext, FC } from "react";
import { config } from "../../config";
import { bindUseRequiredContext, useComputed } from "../../hooks";
import { useFirebaseAuth } from "../../modules/firebase/auth/provider";
import { useFirebaseDatabase } from "../../modules/firebase/database/provider";
import { useFirebaseFunctions } from "../../modules/firebase/functions/provider";
import { SchoolsService } from "./index";
import { ISchoolsService } from "./types";

export const SchoolsServiceContext = createContext<ISchoolsService | undefined>(
  undefined
);
SchoolsServiceContext.displayName = "SchoolsServiceContext";
export const SchoolsServiceProvider: FC = props => {
  const fbAuth = useFirebaseAuth();
  const firebaseFunctions = useFirebaseFunctions();
  const firebaseDatabase = useFirebaseDatabase();
  const schoolsService = useComputed(
    () =>
      new SchoolsService(firebaseDatabase, fbAuth, firebaseFunctions, config),
    [firebaseDatabase, fbAuth, firebaseFunctions, config]
  );

  return (
    <SchoolsServiceContext.Provider value={schoolsService}>
      {props.children}
    </SchoolsServiceContext.Provider>
  );
};

export const useSchoolsService = bindUseRequiredContext(SchoolsServiceContext);
