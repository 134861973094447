import {
  endOfWeek,
  format,
  getDayOfYear,
  getYear,
  parse,
  setDayOfYear,
  setYear,
  startOfWeek,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { IPeriod } from "../pages/Schedules/Common/types";
import { IDateRange } from "../entities/users/types";

export function getNumberOfWeek(date: Date): number {
  const clonedDate = new Date(date);
  clonedDate.setUTCDate(
    clonedDate.getUTCDate() + 4 - (clonedDate.getUTCDay() || 7)
  );
  const yearStart = new Date(Date.UTC(clonedDate.getUTCFullYear(), 0, 1));
  const weekNumber = Math.ceil(
    ((clonedDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
  return weekNumber;
}

export function fromDateTimeStr(datetimeStr: string): Date {
  return parse(datetimeStr, "yyyy-MM-dd HH:mm:ss", new Date());
}
export function fromDateTimeStrV2(datetimeStr: string): Date {
  return parse(datetimeStr, "yyyy-MM-dd HH:mm", new Date());
}
export function fromDateStr(dateStr: string): Date {
  return parse(dateStr, "yyyy-MM-dd", new Date());
}
export function fromTimeStr(timeStr: string): Date {
  return parse(timeStr, "HH:mm:ss", new Date());
}
export function fromTimeStrV2(timeStr: string): Date {
  return parse(timeStr, "HH:mm", new Date());
}

export function mergeDayAndTimeParts(day: Date, time: Date): Date {
  const year = getYear(day);
  const dayOfYear = getDayOfYear(day);

  return setDayOfYear(setYear(time, year), dayOfYear);
}

export function toDateTimeStr(datetimeStr: Date): string {
  return format(datetimeStr, "yyyy-MM-dd HH:mm:ss");
}
export function toDateTimeStrV2(datetimeStr: Date): string {
  return format(datetimeStr, "yyyy-MM-dd HH:mm");
}
export function toDateStr(date: Date): string {
  return format(date, "yyyy-MM-dd");
}
export function toTimeStr(time: Date): string {
  return format(time, "HH:mm:ss");
}

export function fromDateOrDateTimeStr(datetimeStr: string | Date): Date {
  return typeof datetimeStr === "string"
    ? parse(datetimeStr, "yyyy-MM-dd HH:mm:ss", new Date())
    : datetimeStr;
}

export function getStartAndEndOfWeekByDate(date: Date): IPeriod {
  return {
    from: startOfWeek(date, { weekStartsOn: 1 }),
    to: endOfWeek(date, { weekStartsOn: 1 }),
  };
}

export function getDateRangeForReq(dateRange: IPeriod): IDateRange {
  return {
    from: format(dateRange.from, "yyyy-MM-dd"),
    to: format(dateRange.to, "yyyy-MM-dd"),
  };
}

export function getDateRangeWithTimeForReq(dateRange: IPeriod): IDateRange {
  return {
    from: format(dateRange.from, "yyyy-MM-dd HH:mm:ss"),
    to: format(dateRange.to, "yyyy-MM-dd HH:mm:ss"),
  };
}

export function timestampToIsoTimeStr(date: number): string {
  return format(utcToZonedTime(date, "UTC"), "yyyy-MM-dd'T'HH:mm:ss'Z'");
}
export function toDateStrFromNumber(timestamp: number): string {
  return format(new Date(timestamp), "yyyy-MM-dd HH:mm");
}
export function timestampToIsoDateStr(date: number): string {
  return format(utcToZonedTime(date, "UTC"), "yyyy-MM-dd");
}
