import React, { createContext, FC } from "react";
import { bindUseRequiredContext, useComputed } from "../../hooks";
import { useFirebaseDatabase } from "../firebase/database/provider";
import { Settings } from "./Settings";
import { ISettings } from "./types";

export const SettingsContext = createContext<ISettings | undefined>(undefined);
SettingsContext.displayName = "SettingsContext";

export const SettingsProvider: FC = props => {
  const database = useFirebaseDatabase();
  const settings = useComputed(() => new Settings(database), [database]);

  return (
    <SettingsContext.Provider value={settings}>
      {props.children}
    </SettingsContext.Provider>
  );
};

export const useSettings = bindUseRequiredContext(SettingsContext);
