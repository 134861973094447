import * as firebase from "firebase";
import { BehaviorSubject, combineLatest, from, Observable, of } from "rxjs";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";
import { extractTrueKeys, isFoundPredicate } from "../../helpers/array";
import { ISchoolsService } from "../../services/SchoolsService/types";
import { IUsersService } from "../../services/UsersService/types";
import { IProfile, ISessionModule } from "./types";

export class SessionModule implements ISessionModule {
  public readonly authState$: Observable<firebase.User | null>;
  public readonly profile$: Observable<IProfile | null>;
  public readonly profileLoading$: BehaviorSubject<boolean>;
  protected readonly fbAuth: firebase.auth.Auth;
  protected readonly usersService: IUsersService;
  constructor(
    fbAuth: firebase.auth.Auth,
    usersService: IUsersService,
    schoolsService: ISchoolsService
  ) {
    this.profileLoading$ = new BehaviorSubject<boolean>(true);
    this.fbAuth = fbAuth;
    this.usersService = usersService;
    this.authState$ = createAuthStateObservable(fbAuth).pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );
    this.profile$ = createProfile$(
      this.authState$,
      this.usersService,
      schoolsService
    ).pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
      tap(() => this.profileLoading$.next(false))
    );
  }

  public async logout(): Promise<void> {
    return this.fbAuth.signOut();
  }
}

function createProfile$(
  authState$: Observable<firebase.User | null>,
  usersService: IUsersService,
  schoolsService: ISchoolsService
): Observable<IProfile | null> {
  return authState$.pipe(
    switchMap(authState =>
      authState
        ? combineLatest([
            usersService.getUserByAuthId$(authState.uid),
            from(authState.getIdTokenResult().then(result => result.claims)),
            getInstructorSchools$(authState.uid, schoolsService),
            schoolsService.getHasAccessToInstructors$(authState.uid).pipe(
              switchMap(hasAccessToInstructors => {
                return hasAccessToInstructorsN$(
                  hasAccessToInstructors,
                  usersService
                ).pipe(
                  switchMap(hasAccessToInstructorsN => {
                    return getAvailableStudentsN$(
                      hasAccessToInstructorsN,
                      schoolsService
                    ).pipe(
                      map(availableStudentsN => ({
                        availableStudentsN,
                        hasAccessToInstructors,
                        hasAccessToInstructorsN,
                      }))
                    );
                  })
                );
              })
            ),
          ]).pipe(
            map(
              ([
                dbUser,
                customClaims,
                { instructor, owner },
                {
                  availableStudentsN,
                  hasAccessToInstructors,
                  hasAccessToInstructorsN,
                },
              ]) => {
                if (dbUser) {
                  const { id, roles, locations, associationsIds } = dbUser;
                  console.log("DB USER:", dbUser.locations);
                  return {
                    // admin: !!customClaims.admin,
                    admin: roles.includes("admin"),
                    availableStudentsN: new Set(availableStudentsN),
                    displayName: dbUser.name.en,
                    fbUser: authState,
                    instructorAtSchools: instructor,
                    hasAccessToInstructors: new Set(hasAccessToInstructors),
                    hasAccessToInstructorsN: new Set(hasAccessToInstructorsN),
                    ownerAtSchools: owner,
                    id,
                    locationIds: associationsIds?.locations || [],
                    locations: locations || [],
                    // roles: dbUser.roles as Role[],
                  };
                } else {
                  return null;
                }
              }
            )
          )
        : of(null)
    ),
    tap(profile => console.debug({ profile }))
  );
}

function getInstructorSchools$(
  instructorId: string,
  schoolsService: ISchoolsService
): Observable<{
  owner: Set<string>;
  instructor: Set<string>;
}> {
  return schoolsService.getAllSchools$().pipe(
    map(schools => {
      const owner = schools
        .map(school => {
          const ownerOfSchool = extractTrueKeys(school.data.owners).some(
            key => key === instructorId
          );
          return ownerOfSchool ? school : undefined;
        })
        .filter(isFoundPredicate)
        .map(school => school.schoolId);
      const instructor = schools
        .map(school => {
          const ownerOfSchool = extractTrueKeys(school.data.instructors).some(
            key => key === instructorId
          );
          return ownerOfSchool ? school : undefined;
        })
        .filter(isFoundPredicate)
        .map(school => school.schoolId);

      return {
        instructor: new Set(instructor),
        owner: new Set(owner),
      };
    })
  );
}
function createAuthStateObservable(
  auth: firebase.auth.Auth
): Observable<firebase.User | null> {
  return new Observable(subscriber => {
    return auth.onAuthStateChanged(subscriber);
  });
}

function hasAccessToInstructorsN$(
  hasAccessToInstructors: string[],
  usersService: IUsersService
): Observable<number[]> {
  if (hasAccessToInstructors.length === 0) {
    return of([]);
  }
  return combineLatest(
    hasAccessToInstructors.map(uid => usersService.getUserByAuthId$(uid))
  ).pipe(
    map(dbUsers => dbUsers.filter(isFoundPredicate)),
    map(dbUsers => dbUsers.map(dbUser => dbUser.id).filter(isFoundPredicate))
  );
}

function getAvailableStudentsN$(
  hasAccessToInstructorsN: number[],
  schoolsService: ISchoolsService
): Observable<number[]> {
  if (hasAccessToInstructorsN.length === 0) {
    return of([]);
  }
  return combineLatest(
    hasAccessToInstructorsN.map(instructorId =>
      schoolsService.getInstructorStudents$(instructorId)
    )
  ).pipe(map(chunks => Array.from(new Set(chunks.flat()))));
}
