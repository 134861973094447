/* eslint-disable @typescript-eslint/camelcase */
import * as t from "io-ts";
import { GearType, GearTypeMc } from "../../entities/vehicles/types";
import {
  nullable,
  stringNumber,
  ApiDate,
  ApiDateTime,
  unsafeFirebaseDict,
  ApiDateTimeV2,
  ISODate,
  TimestampType,
  FirebaseRecord,
} from "../../io-ts";
import { LanguageKeys } from "../../api/v3/clients/types";

export const UsersConsents = t.partial({
  eula: t.partial({
    when: ISODate,
  }),
  marketing: t.partial({
    when: ISODate,
  }),
});
export const StudentExpiration = t.type({
  date: ApiDate,
});

export const DbUser = t.intersection([
  t.type({
    firstName: t.string,
    firstName_lwrCase: t.string,
    lastName: t.string,
    lastName_lwrCase: t.string,
    email: t.string,
    roles: t.array(t.string),
  }),
  t.partial({
    bookingClientId: stringNumber,
    consents: UsersConsents,
    phone: t.string,
    ssn: t.string,
    isCoreStudent: t.boolean,
  }),
]);

export interface IDbUser extends t.TypeOf<typeof DbUser> {}

export const UserPermits = t.intersection([
  t.type({
    email: t.string,
  }),
  t.partial({
    expiration: StudentExpiration,

    hideForPermissions: t.boolean,
  }),
]);

export interface IUserPermits extends t.TypeOf<typeof UserPermits> {}

const TranslatedField = t.type({
  en: t.string,
  sw: t.string,
});

const TranslatedDescrField = t.type({
  en: nullable(t.string),
  sw: nullable(t.string),
});

const InstructorOptions = t.partial({
  defaultStudentsQty: t.number,
  isVisible: t.boolean,
  isBookable: t.boolean,
});

const UserRoles = t.keyof({
  instructor: null,
  admin: null,
  schoolOwner: null,
});

export const LocationV3 = t.intersection([
  t.type({
    id: t.number,
    name: TranslatedField,
    description: TranslatedField,
    city: t.string,
    zip: t.string,
    lat: t.string,
    lng: t.string,
    address: t.string,
    isActive: t.boolean,
    isVisible: t.boolean,
    listOrder: t.number,
    regionId: t.number,
    image: nullable(t.string),
  }),
  t.partial({
    addressHints: nullable(t.string),
    associationsIds: t.partial({
      users: t.array(t.number),
      services: t.array(t.number),
    }),
  }),
]);

export const UserV3 = t.intersection([
  t.type({
    id: t.number,
    authId: t.string,
    name: TranslatedField,
    description: TranslatedDescrField,
    languagesSpoken: t.array(t.string),
    comment: nullable(t.string),
    email: t.string,
    phone: t.string,
    picture: nullable(t.string),
    isDisabled: t.boolean,
    isAdmin: t.boolean,
    isInstructor: t.boolean,
    isSchoolOwner: t.boolean,
    roles: t.array(UserRoles),
  }),
  t.partial({
    options: t.partial({
      instructor: InstructorOptions,
      owner: t.string,
    }),
    locations: t.array(LocationV3),
    createdAt: ISODate,
    updatedAt: ISODate,
    deletedAt: nullable(ISODate),
    associationsIds: t.type({
      locations: t.array(t.number),
      services: t.array(t.number),
      ownVehiclesIds: t.array(t.number),
      sharedVehiclesIds: t.array(t.number),
    }),
  }),
]);

export interface IUserV3 extends t.TypeOf<typeof UserV3> {}

export const DbClientV3 = t.intersection([
  t.type({
    id: t.number,
    name: t.string,
    email: nullable(t.string),
    phone: nullable(t.string),
    ssn: t.string,
    authId: t.string,
    permitExpDate: nullable(t.string),
  }),
  t.partial({
    isCoreStudent: t.boolean,
    options: nullable(
      t.partial({
        // using t.keyof instead of t.union for better perfomance
        // https://github.com/gcanti/io-ts/blob/master/index.md#union-of-string-literals
        vehicleGearType: t.keyof({
          [GearType.Automatic]: null,
          [GearType.Manual]: null,
        }),
        vehicleGearTypeMc: t.keyof({
          [GearTypeMc.McA]: null,
          [GearTypeMc.McA1]: null,
          [GearTypeMc.McA2]: null,
        }),
        defaultLanguage: t.keyof({
          [LanguageKeys.English]: null,
          [LanguageKeys.Swedish]: null,
        }),
        consents: t.partial({
          eulaDate: ISODate,
          marketingDate: t.string,
        }),
        hideForPermitList: t.boolean,
      })
    ),
  }),
]);

export interface IDbClientV3 extends t.TypeOf<typeof DbClientV3> {}

export const SimplybookDbUser = t.intersection([
  DbUser,
  t.intersection([
    t.type({
      bookingClientId: stringNumber,
      phone: t.string,
      email: t.string,
      ssn: t.string,
      bookingPassword: t.string,
    }),
    t.partial({
      expiration: StudentExpiration,
      consents: UsersConsents,
      firstBookingDate: ApiDateTimeV2,
      lastBookingDate: ApiDateTimeV2,
      graduated: t.boolean,
      hideForPermissions: t.boolean,
    }),
  ]),
]);
export interface ISimplybookDbUser extends t.TypeOf<typeof SimplybookDbUser> {}

export const FoundClient = t.type({
  email: nullable(t.string),
  id: stringNumber,
  name: t.string,
  phone: nullable(t.string),
});
export const Note = t.string;

export interface IFoundClient extends t.TypeOf<typeof FoundClient> {}

export const ImportFromFileResult = t.type({
  result: t.type({
    totalCnt: t.number,
    successfullyImportedCnt: t.number,
  }),
  b64ExcelReport: t.string,
});

export interface IImportFromFileResult
  extends t.TypeOf<typeof ImportFromFileResult> {}

const OptionalApiDateTimeV3 = t.union([
  t.literal(""),
  t.null,
  ApiDateTime,
  ApiDateTimeV2,
]);

export const DbUserProfileV3 = t.intersection([
  t.type({
    balance: t.number,
  }),
  t.partial({
    isAutomaticCar: nullable(t.boolean),
  }),
]);

export interface IDbUserProfileV3 extends t.TypeOf<typeof DbUserProfileV3> {}

export const CurrentEventUnitIds = t.type({
  eventId: stringNumber,
  unitId: stringNumber,
});

export const UserTheoryQuestions = unsafeFirebaseDict(
  t.type({
    learned: t.boolean,
    mastered: t.boolean,
  })
);
export const TestQuestion = t.type({
  answered: t.boolean,
  correct: t.boolean,
  questionName: t.union([stringNumber, t.string]), // it SHOULD be a stringNumber but we have some corrupted data.
});
export const TheoryTestsInfo = t.type({
  completed: t.boolean,
  correct: t.number,
  date: t.string, // FIXME: "05.04.2019 07:09"
  incorrect: t.number,
  questions: unsafeFirebaseDict(TestQuestion),
  timeUsed: t.string,
  unanswered: t.number,
});
export interface ITheoryTestsInfo extends t.TypeOf<typeof TheoryTestsInfo> {}
export const UserTheoryTests = FirebaseRecord(
  t.record(t.string, TheoryTestsInfo)
);
export const UserTheory = t.intersection([
  t.type({
    tests: UserTheoryTests,
  }),
  t.partial({
    questions: UserTheoryQuestions,
  }),
]);
export interface IUserTheory extends t.TypeOf<typeof UserTheory> {}

const ChapterInfo = t.intersection([
  t.type({
    title: t.string,
  }),
  t.partial({
    displayedName: t.string,
  }),
]);
const Chapters = t.record(t.string, ChapterInfo);

export const QuestionsMetaInfo = t.type({
  chapters: Chapters,
  questionChapters: t.record(t.string, t.union([t.string, t.undefined])),
});

export const QuestionsMetaInfoArrayFormat = t.type({
  chapters: Chapters,
  questionChapters: t.readonlyArray(t.union([t.string, t.undefined])),
});

export interface IQuestionsMetaInfo
  extends t.TypeOf<typeof QuestionsMetaInfo> {}

export const DrivingPlanMetaStringsData = t.type({
  groupTitles: t.record(t.string, t.string),
  itemTexts: t.record(t.string, t.string),
});
export const DrivingPlanMetaStrings = t.type({
  sv_SE: DrivingPlanMetaStringsData,
});
export interface IDrivingPlanMetaStrings
  extends t.TypeOf<typeof DrivingPlanMetaStrings> {}
export const DrivingPlanMetaSubgroups = t.readonlyArray(
  t.type({
    itemIds: t.readonlyArray(t.string),
  })
);
export const DrivingPlanMetaGroup = t.type({
  id: t.string,
  subgroups: DrivingPlanMetaSubgroups,
});
export interface IDrivingPlanMetaGroup
  extends t.TypeOf<typeof DrivingPlanMetaGroup> {}
export const DrivingPlanMetaStructure = t.type({
  groups: t.readonlyArray(DrivingPlanMetaGroup),
});

export const DrivingGroupsItem = t.type({
  default: t.string,
  en: t.string,
});

export const DrivingGroupsMeta = t.type({
  subItems: t.array(DrivingGroupsItem),
  title: DrivingGroupsItem,
});

export interface IDrivingGroupsMeta
  extends t.TypeOf<typeof DrivingGroupsMeta> {}

export const DrivingPlanMeta = t.type({
  strings: DrivingPlanMetaStrings,
  structure: DrivingPlanMetaStructure,
});

export interface IDrivingPlanMeta extends t.TypeOf<typeof DrivingPlanMeta> {}

export const UserDrivingPlanCounters = t.type({
  finishedItems: t.number,
  inProgressItems: t.number,
  instructorComments: t.number,
  studentComments: t.number,
});
const InstructorEvent = t.partial({
  instructorId: t.string,
});
export const UserDrivingPlanCommentEvent = t.intersection([
  t.type({
    comment: t.string,
    ts: TimestampType,
  }),
  InstructorEvent,
]);
export interface IUserDrivingPlanCommentEvent
  extends t.TypeOf<typeof UserDrivingPlanCommentEvent> {}
export const UserDrivingPlanCheckedEvent = t.intersection([
  t.type({
    statusChange: t.literal("checked"),
    ts: TimestampType,
  }),
  InstructorEvent,
]);
export interface IUserDrivingPlanCheckedEvent
  extends t.TypeOf<typeof UserDrivingPlanCheckedEvent> {}
export const UserDrivingPlanUnCheckedEvent = t.intersection([
  t.type({
    statusChange: t.literal("unchecked"), // TODO: is it unchecked or something else?
    ts: TimestampType,
  }),
  InstructorEvent,
]);
export const UserDrivingPlanEvent = t.union([
  UserDrivingPlanCommentEvent,
  UserDrivingPlanCheckedEvent,
  UserDrivingPlanUnCheckedEvent,
]);
export type UserDrivingPlanEventType = t.TypeOf<typeof UserDrivingPlanEvent>;
export const UserDrivingPlanSubGroup = t.partial({
  dummy: t.number,
  events: t.record(t.string, UserDrivingPlanEvent),
});
export interface IUserDrivingPlanSubGroup
  extends t.TypeOf<typeof UserDrivingPlanSubGroup> {}
export const UserDrivingPlanGroup = t.record(t.string, UserDrivingPlanSubGroup);
export interface IUserDrivingPlanGroup
  extends t.TypeOf<typeof UserDrivingPlanGroup> {}
export const UserDrivingPlanGroupSummary = t.intersection([
  t.type({
    status: t.union([
      t.literal("notStarted"),
      t.literal("inProgress"),
      t.literal("finished"),
    ]),
  }),
  t.partial({
    lastReadTime: t.record(t.string, TimestampType),
    ts: TimestampType,
  }),
]);
export interface IUserDrivingPlanGroupSummary
  extends t.TypeOf<typeof UserDrivingPlanGroupSummary> {}
export const UserDrivingPlan = t.type({
  counters: UserDrivingPlanCounters,
  groups: t.record(t.string, UserDrivingPlanGroup),
  groupsSummary: t.record(t.string, UserDrivingPlanGroupSummary),
});

export const UserDrivingPlanGroupsSummary = t.record(
  t.string,
  UserDrivingPlanGroupSummary
);

export const DrivingHistoryItem = t.type({
  bookingId: t.number,
  instructorId: t.string,
  location: t.string,
  comment: t.string,
  items: FirebaseRecord(
    t.record(
      t.string,
      t.record(
        t.string,
        t.type({
          status: t.union([
            t.literal("notStarted"),
            t.literal("inProgress"),
            t.literal("finished"),
            t.literal("failed"),
          ]),
        })
      )
    )
  ),
});
export interface IDrivingHistoryItem
  extends t.TypeOf<typeof DrivingHistoryItem> {}

export const DrivingHistoryItemCreatePayload = t.type({
  bookingId: t.number,
  location: t.string,
  comment: t.string,
  items: FirebaseRecord(
    t.record(
      t.string,
      t.record(
        t.string,
        t.type({
          status: t.union([
            t.literal("notStarted"),
            t.literal("inProgress"),
            t.literal("finished"),
            t.literal("failed"),
          ]),
        })
      )
    )
  ),
});
export interface IDrivingHistoryItemCreatePayload
  extends t.TypeOf<typeof DrivingHistoryItemCreatePayload> {}

export const Sys = t.type({
  id: t.string,
  linkType: t.string,
  type: t.string,
});

export const SimpleQuestionItem = t.type({
  fields: t.type({
    correctAnswer: t.string,
    media: t.type({
      sys: Sys,
    }),
    questionName: t.string,
    questionText: t.string,
    reason: t.string,
    theory: t.type({
      sys: Sys,
    }),
    wrongAnswer1: t.string,
    wrongAnswer2: t.string,
    wrongAnswer3: t.string,
  }),
  sys: t.type({
    id: t.string,
  }),
});

export const instructorGoals = FirebaseRecord(t.record(t.string, t.number));
export const instructorGoalsOnMonth = t.number;

export interface IInstructorGoals extends t.TypeOf<typeof instructorGoals> {}

export interface ISimpleQuestionItem
  extends t.TypeOf<typeof SimpleQuestionItem> {}

export const InstructorStudents = FirebaseRecord(
  t.record(stringNumber, t.boolean)
);
export interface InstructorStudents
  extends t.TypeOf<typeof InstructorStudents> {}

export const apiClassInfo = t.intersection([
  t.type({
    approved: t.boolean,
    present: t.boolean,
    bookingId: t.union([t.string, t.number]),
    name: t.string,
    phone: t.string,
    ssn: t.string,
    firebaseId: t.string,
  }),
  t.partial({
    type: t.string,
  }),
]);

export const ClassMemberInfo = t.intersection([
  t.type({
    approved: t.boolean,
    present: t.boolean,
    bookingId: t.string,
    firebaseId: t.string,
    name: t.string,
    phone: t.string,
    ssn: t.string,
    type: t.string,
    comment: nullable(t.boolean),
  }),
  t.partial({
    cardsType: t.intersection([
      t.type({
        type: t.union([
          t.literal("swedishIdCard"),
          t.literal("driverLicense"),
          t.literal("passport"),
          t.literal("other"),
          t.string,
        ]),
      }),
      t.partial({
        value: t.string,
      }),
    ]),
  }),
]);
export const classRow = FirebaseRecord(t.record(t.string, apiClassInfo));

export interface IClassMemberInfo extends t.TypeOf<typeof ClassMemberInfo> {}

export const EducationCardUpdated = FirebaseRecord(
  t.type({
    updated: t.number,
  })
);

export interface IEducationCardUpdated
  extends t.TypeOf<typeof EducationCardUpdated> {}

export const LastVerifiedTimestamp = t.type({
  lastVerified: t.number,
});
export interface ILastVerifiedTimestamp
  extends t.TypeOf<typeof LastVerifiedTimestamp> {}

export const LastUpdatedTimestamp = t.type({
  updated: t.number,
});
export interface ILastUpdatedTimestamp
  extends t.TypeOf<typeof LastUpdatedTimestamp> {}

export const PromoCodeClaims = t.partial({
  redeemPeriod: t.intersection([
    t.type({
      from: t.union([t.number, t.string, t.null]), // unix TS
    }),
    t.partial({
      to: t.union([t.number, t.string, t.null]), // unix TS
    }),
  ]),
  clientsIds: t.union([t.literal("newcomer"), t.array(t.string)]),
  productIds: t.array(t.number),
  // applicable: t.partial({
  //   toWhom: t.union([t.literal("globally"), t.literal("perClient")]),
  //   qty: t.number,
  // }),
  applicableTotalCnt: t.number,
  applicablePerClientCnt: t.number,
});

export const PromoCode = t.intersection([
  t.type({
    availableFrom: t.string,
    availableTo: t.string,
    claims: PromoCodeClaims,
    code: t.string,
    description: t.union([t.string, t.null]),
    isDisabled: t.boolean,
    title: t.string,
    type: t.string,
    value: t.number,
    isAbsolute: t.boolean,
  }),
  t.partial({
    id: t.number,
  }),
]);

export const ApiPromoCode = t.type({
  availableFrom: t.number, // unix TS
  availableTo: t.number, // unix TS
  claims: PromoCodeClaims,
  code: t.string,
  description: t.union([t.string, t.null]),
  id: t.number,
  isDisabled: t.boolean,
  title: t.string,
  type: t.string,
  value: t.number,
  isAbsolute: t.boolean,
  usagesCount: t.number,
  createdAt: t.union([t.number, t.string]),
  updatedAt: t.union([t.null, t.number, t.string]),
});

export const ApiPromoCodes = t.type({
  error: t.union([t.string, t.null]),
  result: t.array(ApiPromoCode),
  total: t.number,
});

export interface IApiPromoCodes extends t.TypeOf<typeof ApiPromoCodes> {}
export const NotifyGlobalSettings = t.type({
  isAvailableForInstructor: t.boolean,
  isAvailableForStudent: t.boolean,
});
export interface INotifyGlobalSettings
  extends t.TypeOf<typeof NotifyGlobalSettings> {}

export const EventsTemplatesConfig = t.type({
  bookingCanceledByAdmin: t.string,
  bookingCreatedByAdmin: t.string,
  bookingCreatedByStudent: t.string,
  bookingUpdatedByAdmin: t.string,
  bookingUpdatedByStudent: t.string,
});
export interface IEventsTemplatesConfig
  extends t.TypeOf<typeof EventsTemplatesConfig> {}

export const EmailTemplate = t.intersection([
  t.type({
    htmlPart: t.string,
    subjectPart: t.string,
    textPart: t.string,
  }),
  t.partial({
    templateName: t.string,
  }),
]);

export interface IEmailTemplate extends t.TypeOf<typeof EmailTemplate> {}

export const EmailTemplatesList = t.type({
  templates: t.record(t.string, t.number),
  nextToken: t.string,
});
export interface IEmailTemplatesList
  extends t.TypeOf<typeof EmailTemplatesList> {}
