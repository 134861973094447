/* eslint-disable @typescript-eslint/camelcase */
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";
import {
  ApiDate,
  ApiDateTime,
  ApiTime,
  ApiTimeV2,
  ISODate,
  nullable,
  optional,
  stringNumber,
} from "../../io-ts";
import { either } from "fp-ts/lib/Either";
import { GearType, GearTypeMc } from "../../entities/vehicles/types";
import { ServiceVehicleType } from "../../entities/services/types";

export const ApiBooking = t.intersection([
  t.type({
    client: nullable(t.string),
    client_email: nullable(t.string),
    client_id: nullable(t.string),
    client_phone: nullable(t.string),
    code: t.string,
    comment: t.string,
    end_date: ApiDateTime,
    event: t.string,
    event_id: NumberFromString,
    id: NumberFromString,
    isDriving: t.boolean,
    isUTB: t.boolean,
    is_confirm: t.union([t.literal("0"), t.literal("1")]),
    location: NumberFromString,
    offset: t.string,
    record_date: t.string,
    start_date: ApiDateTime,
    unit: nullable(t.string),
    unit_email: nullable(t.string),
    unit_id: nullable(NumberFromString),
    payment_status: nullable(t.string),
    isLocked: t.boolean,
  }),
  t.partial({
    break: t.number,
  }),
]);

export interface IApiBooking extends t.TypeOf<typeof ApiBooking> {
  //
}

const BookingClientData = t.intersection([
  t.type({
    ssn: t.string,
    name: t.string,
    phone: t.string,
    email: t.string,
  }),
  t.partial({
    authId: t.string,
  }),
]);
const BookingServiceData = t.type({
  key: t.string,
  name: t.string,
  isClass: t.boolean,
  priceWithVat: t.number,
  vehicleType: t.keyof({
    [ServiceVehicleType.Car]: null,
    [ServiceVehicleType.Motorcycle]: null,
    [ServiceVehicleType.Moped]: null,
  }),
});
const BookingInstructorData = t.type({
  name: t.string,
  email: t.string,
  authId: t.string,
});
const BookingLogDoer = t.type({
  id: t.number,
  role: t.string,
  email: t.string,
});
const BookingLog = t.type({
  id: t.number,
  operationType: t.string,
  doer: BookingLogDoer,
  createdAt: nullable(ISODate),
  updatedAt: nullable(ISODate),
});
const SharedResourceVehicle = t.type({
  id: t.number,
  domId: t.string,
  gearType: t.keyof({
    [GearType.Automatic]: null,
    [GearType.Manual]: null,
    ["2wheel"]: null,
    [GearTypeMc.McA]: null,
    [GearTypeMc.McA1]: null,
    [GearTypeMc.McA2]: null,
  }),
  plateInfo: t.string,
});
const SharedResourceData = t.type({
  type: t.string,
  data: SharedResourceVehicle,
});
export const ApiBookingV3 = t.intersection([
  t.type({
    id: t.number,
    code: t.string,
    clientId: t.number,
    clientData: nullable(BookingClientData),
    comment: nullable(t.string),
    drivingComment: nullable(t.string),
    serviceId: t.number,
    serviceData: nullable(BookingServiceData),
    locationId: t.number,
    startAt: ISODate,
    endAt: ISODate,
    instructorId: nullable(t.number),
    instructorData: nullable(BookingInstructorData),
    isPaid: t.boolean,
    orderId: nullable(t.number),
    isDriving: t.boolean,
    isUbk: t.boolean,
    isLocked: t.boolean,
    isCanceled: t.boolean,
    canceledAt: nullable(t.number),
    createdAt: ISODate,
    updatedAt: ISODate,
    expiredAt: nullable(ISODate),
    deletedAt: nullable(ISODate),
    sharedResourceData: nullable(SharedResourceData),
  }),
  t.partial({
    history: BookingLog,
    break: t.number,
    isKkj: t.boolean,
    seatId: nullable(t.string),
  }),
]);

export interface IApiBookingV3 extends t.TypeOf<typeof ApiBookingV3> {
  //
}
export interface IEditBookPayload {
  id: number;
  eventId: number;
  unitId: number;
  // locationId: string;
  start: Date;
  // endDate: string;
  // endTime: string;
  clientId: number;
  isUTB?: boolean;
  isDriving?: boolean;
}

function isDateInterval(u: unknown): u is [Date, Date] {
  return (
    Array.isArray(u) &&
    u.length === 2 &&
    u[0] instanceof Date &&
    u[1] instanceof Date
  );
}
export function intervalToString(start: Date, end: Date): string {
  return `${start
    .valueOf()
    .toString()
    .padStart(13, "0")}_${end
    .valueOf()
    .toString()
    .padStart(13, "0")}`;
}
export const TimeIntervalIndex = new t.Type<[Date, Date], string, unknown>(
  "TimeIntervalIndex",
  (u): u is [Date, Date] => isDateInterval(u),
  (u, c) => {
    return either.chain(t.string.validate(u, c), s => {
      const chunks = s.split("_");
      if (chunks.length !== 2) {
        return t.failure(u, c);
      }
      const [startStr, endStr] = chunks;
      const [startN, endN] = [Number(startStr), Number(endStr)];
      if (Number.isSafeInteger(startN) && Number.isSafeInteger(endN)) {
        const start = new Date(startN);
        const end = new Date(endN);
        return t.success([start, end]);
      }

      return t.failure(u, c);
    });
  },
  ([start, end]) => intervalToString(start, end)
);
export const ApiCustomSlotBase = t.type({
  comment: optional(t.string),
  interval: TimeIntervalIndex,
});

export const ApiVabSlot = t.intersection([
  ApiCustomSlotBase,
  t.type({
    type: t.literal("Vab"),
  }),
]);
export interface IApiVabSlot extends t.TypeOf<typeof ApiVabSlot> {}

export const ApiKompUtSlot = t.intersection([
  ApiCustomSlotBase,
  t.type({
    type: t.literal("KompUt"),
  }),
]);
export interface IApiKompUtSlot extends t.TypeOf<typeof ApiKompUtSlot> {}

export const StudentDashboard = t.type({
  completed: t.number,
  scheduled: t.number,
  left: t.number,
});

export interface IStudentDashboard extends t.TypeOf<typeof StudentDashboard> {}

const FakeTimeCodec = t.union([ApiTime, ApiDate]);

export const FakeRisk2 = t.type({
  date: ApiDate,
  externalProvider: t.string,
  endTime: FakeTimeCodec,
  startTime: FakeTimeCodec,
});

export interface IFakeRisk2 extends t.TypeOf<typeof FakeRisk2> {}

const UserExamTypeC = t.union([
  t.literal("utb"),
  t.literal("driving"),
  t.literal("theory"),
  t.literal("risk1"),
  t.literal("risk2"),
]);
const UserExamResultC = t.union([
  t.literal("none"),
  t.literal("approved"),
  t.literal("not_approved"),
  t.literal("skipped"),
]);
export type UserExamType = t.TypeOf<typeof UserExamTypeC>;
export type UserExamResult = t.TypeOf<typeof UserExamResultC>;

//  FIXME: data are inconsistent
const UserExamTimeType = t.union([ApiTime, ApiTimeV2]);
export const UserExam = t.intersection([
  t.type({
    bookingId: t.union([
      t.literal(false),
      stringNumber,
      t.readonlyArray(stringNumber),
    ]),
    date: ApiDate,
    endTime: UserExamTimeType,
    // TODO: get values from enum
    result: UserExamResultC,
    startTime: UserExamTimeType,
    // TODO: get values from enum
    type: UserExamTypeC,
  }),
  t.partial({
    comment: t.string,
    updatedAt: nullable(ApiDateTime),
    vehicleType: t.keyof({
      [ServiceVehicleType.Car]: null,
      [ServiceVehicleType.Motorcycle]: null,
      [ServiceVehicleType.Moped]: null,
    }),
  }),
]);

export interface IUserExam extends t.TypeOf<typeof UserExam> {}

export const DbBooking = t.intersection([
  t.type({
    bookingClientId: t.union([t.number, stringNumber]),
    bookingDate: ApiDateTime,
    eventId: t.union([t.number, stringNumber]),
    status: t.union([t.literal("unpaid"), t.literal("paid")]),
  }),
  t.partial({
    bookingType: t.string,
    type: t.string,
    unitId: t.string,
    lastEventType: t.union([
      t.literal("create"),
      t.literal("cancel"),
      t.literal("change"),
    ]),
  }),
]);
export interface IDbBooking extends t.TypeOf<typeof DbBooking> {}

export const ApiResponse = t.type({
  data: t.boolean,
});

export interface IApiResponse extends t.TypeOf<typeof ApiResponse> {}

export const PurgeClientInfo = t.type({
  firstName: t.string,
  lastName: t.string,
  phone: t.string,
  ssn: t.string,
});

export interface IPurgeClientInfo extends t.TypeOf<typeof PurgeClientInfo> {}

export const ApiStudentInfo = t.type({
  profile: PurgeClientInfo,
});

export interface IApiStudentInfo extends t.TypeOf<typeof ApiStudentInfo> {}

export const PurgeClientResult = t.type({
  deletedFromFbUsers: t.boolean,
  deletedFromFbRegisteredPhones: t.boolean,
  deletedFromFbAuth: t.boolean,
  deletedFromFbBookingClientsIdsMap: t.boolean,
  deletedFromFbUserIdIndex: t.boolean,
  deletedFromSbUsers: t.boolean,
});

export interface IPurgeClientResult
  extends t.TypeOf<typeof PurgeClientResult> {}
