import React, { createContext, FC } from "react";
import { config } from "../../config";
import { bindUseRequiredContext, useComputed } from "../../hooks";
import { useFirebaseAuth } from "../../modules/firebase/auth/provider";
import { useFirebaseDatabase } from "../../modules/firebase/database/provider";
import { BookingsService } from "./BookingsService";
import { IBookingsService } from "./types";

export const BookingsServiceContext = createContext<
  IBookingsService | undefined
>(undefined);
BookingsServiceContext.displayName = "BookingsServiceContext";

export const BookingsServiceProvider: FC = props => {
  const fbAuth = useFirebaseAuth();
  const fbDatabase = useFirebaseDatabase();
  const bookingsService = useComputed(
    () => new BookingsService(fbAuth, config, fbDatabase),
    [fbAuth, fbDatabase]
  );

  return (
    <BookingsServiceContext.Provider value={bookingsService}>
      {props.children}
    </BookingsServiceContext.Provider>
  );
};

export const useBookingsService = bindUseRequiredContext(
  BookingsServiceContext
);
