import * as t from "io-ts";
export const FirebaseConfig = t.type({
  appId: t.string,
  apiKey: t.string,
  authDomain: t.string,
  databaseURL: t.string,
  projectId: t.string,
  storageBucket: t.string,
  messagingSenderId: t.string,
});

export const InstructorUiConfig = t.type({
  urlPrefix: t.string,
});
export const BugsnagConfig = t.type({
  apiKey: t.string,
});
export const Config = t.type({
  masterApiUrl: t.string,
  apiUrl: t.string,
  secApiUrl: t.string,
  firebase: FirebaseConfig,
  instructorui: InstructorUiConfig,
  bugsnag: BugsnagConfig,
  intercomId: t.string,
  mdaCoreApiUrl: t.string,
  notificationsApiUrl: t.string,
  bonniersApi: t.string,
});
export interface IConfig extends t.TypeOf<typeof Config> {}
