import * as t from "io-ts";
import { FirebaseRecord } from "../../io-ts";

export const DrivingSchoolInfoInstructors = FirebaseRecord(
  t.record(t.string, t.literal(true))
);

export const DrivingSchoolInfoOwners = FirebaseRecord(
  t.record(t.string, t.literal(true))
);

export const DrivingSchoolInfo = t.intersection([
  t.type({
    title: t.string,
    address: t.string,
    owners: DrivingSchoolInfoOwners,
    instructors: DrivingSchoolInfoInstructors,
  }),
  t.partial({
    protected: t.literal(true),
    hiddenInstructors: DrivingSchoolInfoInstructors,
  }),
]);

export interface IDrivingSchoolInfo
  extends t.TypeOf<typeof DrivingSchoolInfo> {}
