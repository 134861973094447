import { isRight } from "fp-ts/lib/Either";
import * as t from "io-ts";
import {
  fromDateStr,
  fromDateTimeStr,
  fromDateTimeStrV2,
  fromTimeStr,
  fromTimeStrV2,
  toDateStr,
  toDateTimeStr,
  toDateTimeStrV2,
  toTimeStr,
} from "./helpers/datetime";

export function isNumber(u: unknown): u is number {
  return typeof u === "number";
}

export const stringNumber = new t.Type<number, string, unknown>(
  "stringNumber",
  isNumber,
  (u, c) => {
    if (typeof u !== "string") {
      return t.failure(u, c);
    }
    const n = Number(u);
    if (Number.isNaN(n)) {
      return t.failure(u, c, "Not a number");
    } else {
      return t.success(n);
    }
  },
  v => v.toString()
);

export function nullable<T extends t.Any>(codec: T) {
  return t.union([codec, t.null]);
}

export function optional<T extends t.Any>(codec: T) {
  return t.union([codec, t.undefined]);
}

/**
 * Firebase dictionary with integer keys sometimes is a spare array
 * @param codec
 */
export function unsafeFirebaseDict<T extends t.Any>(codec: T) {
  return t.union([
    t.record(stringNumber, codec),
    t.readonlyArray(t.union([codec, t.undefined])),
  ]);
}

// TODO: custom codec with checking errorCode
export const ApiResponse = <C extends t.Mixed>(codec: C) =>
  t.type({
    data: codec,
    errorCode: t.number,
  });

export const ApiResponseV3 = <C extends t.Mixed>(codec: C) =>
  t.type({
    data: t.type({
      result: codec,
      total: t.number,
    }),
    errorCode: t.number,
  });

const isDate = (u: unknown): u is Date => u instanceof Date;
export interface IT24DateType extends Date {
  readonly t24: boolean; // if t24 is true - time string was 24:00:00;
}
const isT24Date = (u: unknown): u is IT24DateType => {
  if (u instanceof Date) {
    if (typeof u === "object" && u) {
      const t24 = (u as any).t24;
      if (typeof t24 === "boolean") {
        return true;
      }
    }
  }

  return false;
};
export const ISODate = new t.Type<Date, string, unknown>(
  "ApiDate",
  isDate,
  (u, c) => {
    if (typeof u !== "string") {
      return t.failure(u, c, "wrong date format");
    }
    const d = new Date(u);
    return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
  },
  a => toDateStr(a)
);
export const ApiDate = new t.Type<Date, string, unknown>(
  "ApiDate",
  isDate,
  (u, c) => {
    if (typeof u !== "string") {
      return t.failure(u, c, "wrong date format");
    }
    const d = fromDateStr(u);
    return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
  },
  a => toDateStr(a)
);

export const DateType = new t.Type<Date, Date, unknown>(
  "Date",
  isDate,
  (u, c) => {
    return u instanceof Date ? t.success(u) : t.failure(u, c);
  },
  t.identity
);

export const TimestampType = new t.Type<Date, number, unknown>(
  "Timestamp",
  isDate,
  (u, c) => {
    if (typeof u !== "number") {
      return t.failure(u, c);
    }
    return t.success(new Date(u));
  },
  a => a.valueOf()
);

export const ApiTime = new t.Type<IT24DateType, string, unknown>(
  "ApiTime",
  isT24Date,
  (u, c) => {
    let is24 = false;
    if (u === "24:00:00") {
      u = "00:00:00";
      is24 = true;
    }
    if (typeof u !== "string") {
      return t.failure(u, c, "wrong date format");
    }
    const d = fromTimeStr(u);
    if (isNaN(d.getTime())) {
      return t.failure(u, c);
    }

    const res = Object.assign(d, { t24: is24 });
    return t.success(res);
  },
  a => {
    if (a.t24) {
      return "24:00:00";
    } else {
      return toTimeStr(a);
    }
  }
);
export const ApiTimeV2 = new t.Type<IT24DateType, string, unknown>(
  "ApiTimeV2",
  isT24Date,
  (u, c) => {
    let is24 = false;
    if (u === "24:00") {
      u = "00:00";
      is24 = true;
    }
    if (typeof u !== "string") {
      return t.failure(u, c, "wrong date format");
    }
    const d = fromTimeStrV2(u);
    if (isNaN(d.getTime())) {
      return t.failure(u, c);
    }

    const res = Object.assign(d, { t24: is24 });
    return t.success(res);
  },
  a => {
    if (a.t24) {
      return "24:00";
    } else {
      return toTimeStr(a);
    }
  }
);
export const ApiDateTime = new t.Type<Date, string, unknown>(
  "ApiDateTime",
  isDate,
  (u, c) => {
    if (typeof u !== "string") {
      return t.failure(u, c, "wrong date format");
    }
    const d = fromDateTimeStr(u);
    return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
  },
  a => toDateTimeStr(a)
);

/**
 *  Empty record is undefined
 * @param codec
 * @constructor
 */
export function FirebaseRecord<
  A extends Record<string, unknown>,
  O = A,
  I = unknown
>(codec: t.Type<A, O, I>): t.Type<A, O, I> {
  return new t.Type<A, O, I>(
    "FirebaseRecord",
    codec.is,
    (u, c) => {
      if (typeof u === "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
        u = {} as I;
      }
      return codec.validate(u, c);
    },
    codec.encode
  );
}

export const ApiDateTimeV2 = new t.Type<Date, string, unknown>(
  "ApiDateTime",
  isDate,
  (u, c) => {
    if (typeof u !== "string") {
      return t.failure(u, c, "wrong date format");
    }
    const d = fromDateTimeStrV2(u);
    return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
  },
  a => toDateTimeStrV2(a)
);

export const TimeInterval = t.type({
  end: DateType,
  start: DateType,
});
export interface ITimeInterval extends t.TypeOf<typeof TimeInterval> {}

export const TimeIntervalISO = t.type({
  end: ISODate,
  start: ISODate,
});
export interface ITimeIntervalISO extends t.TypeOf<typeof TimeIntervalISO> {}

export const ScheduleV3 = t.record(t.string, t.array(TimeIntervalISO));
export interface IScheduleV3 extends t.TypeOf<typeof ScheduleV3> {}

export function isTimeIntervals(u: unknown): u is ITimeInterval[] {
  if (!Array.isArray(u)) {
    return false;
  }
  return u.every(_ => isRight(TimeInterval.decode(_)));
}

export function isTimeIntervalsV3(u: unknown): u is ITimeIntervalISO[] {
  if (!Array.isArray(u)) {
    return false;
  }
  return u.every(_ => isRight(TimeIntervalISO.decode(_)));
}

export function isClassIntervals(
  u: unknown
): u is { [eventId: string]: ITimeInterval[] } {
  if (typeof u === "object" && u) {
    return Object.values(u).every(_ => isRight(TimeInterval.decode(_)));
  } else return false;
}

export const BookingData = t.type({
  bookingId: t.string,
  isCanceled: t.boolean,
  event: t.string,
  instructor: t.string,
  date: t.string,
});
export const KlarnaRefaundData = t.type({
  id: t.string,
  at: t.string,
  description: t.string,
  amount: t.number,
});
export const RefaundData = t.type({
  qty: t.number,
  dateStr: t.string,
  comment: t.string,
  klarnaRefunds: optional(t.array(KlarnaRefaundData)),
});

export const Order = t.type({
  orderId: t.string,
  orderRef: nullable(t.string),
  orderDate: t.string,
  bookings: t.array(BookingData),
  isRefunded: t.boolean,
  refund: RefaundData,
  orderedLessons: t.number,
  availableLessons: t.number,
  price: t.number,
});

export interface IOrder extends t.TypeOf<typeof Order> {}

export const HistoryBookingDataV3 = t.type({
  bookingId: t.number,
  isCanceled: t.boolean,
  service: t.string,
  instructor: t.union([t.number, t.string]),
  date: t.string, //format "YYYY-MM-DD HH:mm:ss"
});

export const HistoryRefundDataV3 = t.type({
  qty: t.number,
  dateStr: t.string,
  comment: t.string,
  klarnaRefunds: optional(t.array(KlarnaRefaundData)),
});

export const OrderHistoryV3 = t.type({
  orderId: t.string,
  orderRef: nullable(t.string),
  orderDate: t.string, // format "YYYY-MM-DD HH:mm:ss"
  invoiceId: nullable(t.string),
  promocode: nullable(t.string),
  price: t.number,
  serviceId: t.number,
  serviceName: t.string,
  instructorsIds: t.array(t.number),
  isClass: t.boolean,
  isServiceAvailable: t.boolean,
  orderedLessons: t.number,
  availableLessons: t.number,
  bookedLessons: t.number,
  bookings: t.array(HistoryBookingDataV3),
  isRefunded: t.boolean,
  refund: HistoryRefundDataV3,
});

export interface IOrderHistoryV3 extends t.TypeOf<typeof OrderHistoryV3> {}
