import { notification } from "antd";
import { ComponentType } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyChunkFactory<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): () => Promise<{ default: T }> {
  const factoryWrapped = (): Promise<{ default: T }> => {
    return factory().catch(error => {
      notification.error({
        description: "The app was updated and have to be reloaded",
        message: "Syntax Error",
      });
      error.bugsnagIgnore = true;
      throw error;
    });
  };

  return factoryWrapped;
}
