import { notification } from "antd";
import * as firebase from "firebase/app";
import { either } from "fp-ts";
import { isLeft } from "fp-ts/lib/Either";
import * as t from "io-ts";
import { Decoder } from "io-ts";
import { from, Observable, of } from "rxjs";
import { concatMap, first, map, switchMap } from "rxjs/operators";
import { IConfig } from "../../config/io-ts";
import {
  fromFetch,
  getBlob$,
  getUserToken$,
  makeGetRequest$,
} from "../../helpers/http";
import { toError } from "../../helpers/io-ts";
import { nullable } from "../../io-ts";
import {
  classRow,
  DbClientV3,
  DbUser,
  DbUserProfileV3,
  DrivingGroupsMeta,
  DrivingHistoryItem,
  DrivingPlanMeta,
  EmailTemplate,
  EmailTemplatesList,
  EventsTemplatesConfig,
  IClassMemberInfo,
  IDbClientV3,
  IDbUser,
  IDbUserProfileV3,
  IDrivingGroupsMeta,
  IDrivingHistoryItem,
  IDrivingHistoryItemCreatePayload,
  IDrivingPlanMeta,
  IEmailTemplate,
  IEmailTemplatesList,
  IEventsTemplatesConfig,
  IInstructorGoals,
  INotifyGlobalSettings,
  instructorGoalsOnMonth,
  IQuestionsMetaInfo,
  ISimplybookDbUser,
  IUserDrivingPlanGroup,
  IUserDrivingPlanGroupSummary,
  IUserPermits,
  IUserTheory,
  IUserV3,
  Note,
  NotifyGlobalSettings,
  QuestionsMetaInfo,
  QuestionsMetaInfoArrayFormat,
  SimplybookDbUser,
  UserDrivingPlanGroup,
  UserDrivingPlanGroupsSummary,
  UserDrivingPlanGroupSummary,
  UserPermits,
  UserTheory,
  UserV3,
} from "./io-ts";
import { IUsersService, SearchUsersColumn } from "./types";
import { RcFile } from "antd/lib/upload/interface";
import { GearType, GearTypeMc } from "../../entities/vehicles/types";
import { LanguageKeys } from "src/api/v3/clients/types";
import { IUsersSearch } from "../../pages/Users/context/types";
import { ServiceVehicleType } from "../../entities/services/types";

export class UsersService implements IUsersService {
  protected readonly database: firebase.database.Database;
  protected readonly storage: firebase.storage.Storage;
  protected readonly fbAuth: firebase.auth.Auth;
  protected readonly fbFunctions: firebase.functions.Functions;
  protected readonly config: IConfig;
  constructor(
    database: firebase.database.Database,
    storage: firebase.storage.Storage,
    fbAuth: firebase.auth.Auth,
    fbFunctions: firebase.functions.Functions,
    config: IConfig
  ) {
    this.database = database;
    this.storage = storage;
    this.fbAuth = fbAuth;
    this.fbFunctions = fbFunctions;
    this.config = config;
  }

  public getStudentPermits$(
    firebaseId: string
  ): Observable<IUserPermits | undefined> {
    return new Observable<IUserPermits | undefined>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        if (val) {
          const res = UserPermits.decode(val);
          if (isLeft(res)) {
            console.debug({ val, firebaseId });
            errorHandler(toError(res));
          } else {
            subscriber.next(res.right);
          }
        } else {
          subscriber.next(undefined);
        }
      };

      const ref = this.database.ref(`/v3/users/${firebaseId}`);
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  public getUserGroupsSummary$(
    firebaseId: string
  ): Observable<{ groupsCompleted: number; groupsLeft: number }> {
    return new Observable<{ groupsCompleted: number; groupsLeft: number }>(
      subscriber => {
        const errorHandler = (err: Error): void => {
          subscriber.error(err);
        };
        const handler = (snapshot: firebase.database.DataSnapshot): void => {
          const val = snapshot.val();
          if (val) {
            const res = UserDrivingPlanGroupsSummary.decode(val);

            if (isLeft(res)) {
              console.debug({ val, firebaseId });
              errorHandler(toError(res));
            } else {
              const list = Object.values(res.right);
              let groupsCompleted = 0;
              let groupsLeft = 0;

              for (const groupId of Object.keys(list)) {
                if (list[+groupId].status === "finished") {
                  groupsCompleted++;
                } else {
                  groupsLeft++;
                }
              }
              subscriber.next({ groupsCompleted, groupsLeft });
            }
          } else {
            subscriber.next({ groupsCompleted: 0, groupsLeft: 0 });
          }
        };

        const ref = this.database.ref(
          `/v3/userDrivingPlans/${firebaseId}/groupsSummary`
        );
        ref.on("value", handler, errorHandler);

        return () => {
          ref.off("value", handler);
        };
      }
    );
  }

  public getFirebaseIdBySSN$(
    ssn: string,
    date: string
  ): Promise<string | undefined> {
    return getFirebaseIdBySSN(ssn, this.database).then(el => {
      el && this.setStudentExpirationDate(el, date);
      return el;
    });
  }
  public setStudentExpirationDate(
    firebaseId: string,
    expiration: string
  ): Observable<void> {
    const ref = this.database.ref(`/v3/users/${firebaseId}/expiration`);

    return from(
      ref.update({
        date: expiration,
      })
    );
  }

  public hideStudentFromPermitsList(
    firebaseId: string,
    flag: boolean
  ): Observable<void> {
    const ref = this.database.ref(`/v3/users/${firebaseId}`);

    return from(
      ref.update({
        hideForPermissions: flag,
      })
    );
  }

  public getStudent$(clientId: number): Observable<IDbClientV3 | undefined> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/clients/${clientId}`;
    return makeGetRequest$<IDbClientV3 | null>(
      endpoint,
      {},
      nullable(DbClientV3),
      this.fbAuth,
      false,
      false,
      true
    ).pipe(map(client => client || undefined)); // CHECKME
  }

  public getStudentsByIds$(
    clientIds: number[]
  ): Observable<IDbClientV3[] | undefined> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/clients/search`;
    return makeGetRequest$<IDbClientV3[]>(
      endpoint,
      {
        ids: clientIds,
      },
      t.array(DbClientV3),
      this.fbAuth,
      false,
      true,
      true
    ).pipe(map(clients => clients));
  }

  public getStudentByAuthId$(
    authId: string
  ): Observable<IDbClientV3 | undefined> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/clients/search?authId=${authId}`;
    // TODO It shouldn't be a search !!
    return makeGetRequest$<IDbClientV3[]>(
      endpoint,
      {},
      t.array(DbClientV3),
      this.fbAuth,
      false,
      true
    ).pipe(map(students => students[0]));
  }

  public getStudentsByAuthIds$(
    authIds: string[]
  ): Observable<IDbClientV3[] | undefined> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/clients/search`;
    return makeGetRequest$<IDbClientV3[]>(
      endpoint,
      {
        authIds,
      },
      t.array(DbClientV3),
      this.fbAuth,
      false,
      true
    ).pipe(map(students => students));
  }

  public getStudentProfile$(
    clientId: number
  ): Observable<IDbUserProfileV3 | undefined> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/clients/${clientId}/profile`;
    return makeGetRequest$<IDbUserProfileV3>(
      endpoint,
      {},
      DbUserProfileV3,
      this.fbAuth
    );
  }

  public updateClientV3(
    clientId: number,
    data: {
      email?: string;
      name?: string;
      ssn?: string;
      phone?: string;
      options?: {
        vehicleGearType?: GearType;
        vehicleGearTypeMc?: GearTypeMc;
        defaultLanguage?: LanguageKeys;
      };
    }
  ): Observable<void> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/clients/${clientId}`;
    const { email, name, ssn, phone, options } = data;
    const vehicleGearType = options?.vehicleGearType;
    const vehicleGearTypeMc = options?.vehicleGearTypeMc;
    const defaultLanguage = options?.defaultLanguage;
    const payload: {
      id: number;
      email?: string;
      name?: string;
      ssn?: string;
      phone?: string;
      options?: {
        vehicleGearType?: GearType;
        vehicleGearTypeMc?: GearTypeMc;
        defaultLanguage?: LanguageKeys;
      };
    } = {
      id: clientId,
      email,
      name,
      ssn,
      phone,
      ...{
        options: {
          vehicleGearType,
          vehicleGearTypeMc,
          defaultLanguage,
        },
      },
    };

    return makeCustomRequest(
      endpoint,
      payload,
      "PUT",
      t.unknown,
      false,
      this.fbAuth
    ).pipe(switchMap(() => of(undefined)));
  }

  public generateStatisticsReport$(): Observable<Blob> {
    const endpoint = `${this.config.apiUrl}/v2/client/balance/statistics/xls`;
    return getBlob$(endpoint, {}, this.fbAuth);
  }

  public getUserTheory$(
    firebaseId: string,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<IUserTheory | undefined> {
    return new Observable<IUserTheory | undefined>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        if (val) {
          const res = UserTheory.decode(val);
          if (isLeft(res)) {
            console.debug({ val, firebaseId });
            errorHandler(toError(res));
          } else {
            subscriber.next(res.right);
          }
        } else {
          subscriber.next(undefined);
        }
      };

      const collectionNames = {
        [ServiceVehicleType.Car]: "theory",
        [ServiceVehicleType.Motorcycle]: "theory_mc",
        [ServiceVehicleType.Moped]: "theory_am",
      };
      const ref = this.database.ref(
        `/v3/${collectionNames[vehicleType]}/${firebaseId}`
      );
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  public getQuestionsMetaInfo$(
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<IQuestionsMetaInfo> {
    return new Observable<IQuestionsMetaInfo>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        if (val) {
          const res = QuestionsMetaInfo.decode(val);
          if (isLeft(res)) {
            const res2 = QuestionsMetaInfoArrayFormat.decode(val);
            if (isLeft(res2)) {
              console.debug({ val });
              errorHandler(toError(res2));
            } else {
              const { chapters, questionChapters } = res2.right;
              const questionChaptersFormated = questionChapters.reduce(
                (obj: { [key: string]: string | undefined }, item, index) => ({
                  ...obj,
                  [index]: item,
                }),
                {}
              );
              subscriber.next({
                chapters,
                questionChapters: questionChaptersFormated,
              });
            }
          } else {
            subscriber.next(res.right);
          }
        } else {
          subscriber.next(undefined);
        }
      };

      const collectionNames = {
        [ServiceVehicleType.Car]: "theoryMeta",
        [ServiceVehicleType.Motorcycle]: "theoryMetaMc",
        [ServiceVehicleType.Moped]: "theoryMetaAm",
      };
      const ref = this.database.ref(`/v3/${collectionNames[vehicleType]}`);
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }
  public getUsersWithoutPermits$(): Observable<
    { firebaseId: string; data: ISimplybookDbUser }[]
  > {
    return new Observable<{ firebaseId: string; data: ISimplybookDbUser }[]>(
      subscriber => {
        const errorHandler = (err: Error): void => {
          subscriber.error(err);
        };
        const handler = (snapshot: firebase.database.DataSnapshot): void => {
          const results: {
            firebaseId: string;
            data: ISimplybookDbUser;
          }[] = [];
          snapshot.forEach(s => {
            const firebaseId = s.key;
            if (typeof firebaseId !== "string") {
              errorHandler(new Error("Key of Users is not a string"));
              return true;
            }
            const rawUser = s.val();
            const res = SimplybookDbUser.decode(rawUser);
            if (either.isLeft(res)) {
              console.warn(rawUser, toError(res));
              // errorHandler(toError(res));
              // return true;
            } else if (res.right.firstBookingDate) {
              const user = res.right;
              if (user.expiration) {
                const currentDate = Date.now();
                const expirationDate = user.expiration.date.getTime();
                if (currentDate > expirationDate) {
                  results.push({ firebaseId, data: res.right });
                }
              } else {
                results.push({ firebaseId, data: user });
              }
            }
          });
          subscriber.next(results);
        };

        const ref = this.database.ref("/v3/users").orderByChild("firstName");
        ref.on("value", handler, errorHandler);

        return () => {
          ref.off("value", handler);
        };
      }
    );
  }

  public searchUsers$(
    query: string,
    column: SearchUsersColumn,
    maxResults: number
  ): Observable<{ firebaseId: string; data: IDbUser }[]> {
    return new Observable<{ firebaseId: string; data: IDbUser }[]>(
      subscriber => {
        const errorHandler = (err: Error): void => {
          subscriber.error(err);
        };
        const handler = (snapshot: firebase.database.DataSnapshot): void => {
          const results: {
            firebaseId: string;
            data: IDbUser;
          }[] = [];
          snapshot.forEach(s => {
            const firebaseId = s.key;
            if (typeof firebaseId !== "string") {
              errorHandler(new Error("Key of Users is not a string"));
              return true;
            }
            const rawUser = s.val();
            const res = DbUser.decode(rawUser);
            if (isLeft(res)) {
              console.warn(rawUser, toError(res));
              // errorHandler(toError(res));
              // return true;
            } else {
              results.push({ firebaseId, data: res.right });
            }
          });
          subscriber.next(results);
        };

        const ref = this.database
          .ref("/v3/users")
          .orderByChild(column)
          .startAt(query)
          .endAt(`${query}\uf8ff`)
          .limitToFirst(maxResults);

        ref.on("value", handler, errorHandler);

        return () => {
          ref.off("value", handler);
        };
      }
    );
  }

  public getDrivingPlanMeta$(
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<IDrivingPlanMeta> {
    return new Observable<IDrivingPlanMeta>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        if (val) {
          const res = DrivingPlanMeta.decode(val);
          if (either.isLeft(res)) {
            console.debug({ val, err: toError(res) });
            errorHandler(toError(res));
          } else {
            subscriber.next(res.right);
          }
        } else {
          subscriber.next(undefined);
        }
      };

      const collectionNames = {
        [ServiceVehicleType.Car]: "drivingPlanMetas",
        [ServiceVehicleType.Motorcycle]: "drivingPlanMetasMc",
        [ServiceVehicleType.Moped]: "drivingPlanMetasAm",
      };
      const ref = this.database.ref(
        `/v3/${collectionNames[vehicleType]}/meta2`
      );
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  public getMetaToGroups(
    groupId: string,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<IDrivingGroupsMeta> {
    return new Observable<IDrivingGroupsMeta>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };

      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        if (val) {
          const res = DrivingGroupsMeta.decode(val);
          if (either.isLeft(res)) {
            console.debug({ val, err: toError(res) });
            errorHandler(toError(res));
          } else {
            subscriber.next(res.right);
          }
        } else {
          subscriber.next(undefined);
        }
      };

      const collectionNames = {
        [ServiceVehicleType.Car]: "drivingExercises",
        [ServiceVehicleType.Motorcycle]: "drivingExercisesMc",
        [ServiceVehicleType.Moped]: "drivingExercisesAm",
      };
      const ref = this.database.ref(
        `/v3_2/${collectionNames[vehicleType]}/${groupId}`
      );
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  public pushDrivingHistoryItem(
    instructorId: string,
    studentFirebaseId: string,
    payload: IDrivingHistoryItemCreatePayload,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<string> {
    const functionName = {
      [ServiceVehicleType.Car]: "pushDrivingHistoryItem",
      [ServiceVehicleType.Motorcycle]: "pushDrivingHistoryItemMc",
      [ServiceVehicleType.Moped]: "pushDrivingHistoryItemAm",
    }[vehicleType];
    const pushDrivingHistoryItem = this.fbFunctions.httpsCallable(functionName);
    const expectedResponse = t.type({
      key: t.string,
    });
    const promise = pushDrivingHistoryItem({
      studentFirebaseId,
      authorId: instructorId,
      drivingHistoryItem: payload,
    }).then(result => {
      const res = expectedResponse.decode(result.data);
      if (either.isLeft(res)) {
        throw toError(res);
      } else {
        return res.right.key;
      }
    });
    return from(promise);
  }

  public deleteDrivingHistoryItem(
    instructorId: string,
    studentFirebaseId: string,
    itemKey: string,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<void> {
    const functionName = {
      [ServiceVehicleType.Car]: "deleteDrivingHistoryItem",
      [ServiceVehicleType.Motorcycle]: "deleteDrivingHistoryItemMc",
      [ServiceVehicleType.Moped]: "deleteDrivingHistoryItemAm",
    }[vehicleType];
    const deleteDrivingHistoryItem = this.fbFunctions.httpsCallable(
      functionName
    );
    const promise = deleteDrivingHistoryItem({
      studentFirebaseId,
      authorId: instructorId,
      key: itemKey,
    });
    return from(promise).pipe(switchMap(() => of(undefined)));
  }

  public updateDrivingHistoryItemComment(
    instructorId: string,
    studentFirebaseId: string,
    itemKey: string,
    comment: string,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<void> {
    const functionName = {
      [ServiceVehicleType.Car]: "updateDrivingHistoryItemComment",
      [ServiceVehicleType.Motorcycle]: "updateDrivingHistoryItemCommentMc",
      [ServiceVehicleType.Moped]: "updateDrivingHistoryItemCommentAm",
    }[vehicleType];
    const updateDrivingHistoryItemComment = this.fbFunctions.httpsCallable(
      functionName
    );
    const promise = updateDrivingHistoryItemComment({
      studentFirebaseId,
      authorId: instructorId,
      key: itemKey,
      comment,
    });
    return from(promise).pipe(switchMap(() => of(undefined)));
  }

  public updateDrivingHistoryItemLocation(
    instructorId: string,
    studentFirebaseId: string,
    itemKey: string,
    location: string,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<void> {
    const functionName = {
      [ServiceVehicleType.Car]: "updateDrivingHistoryItemLocation",
      [ServiceVehicleType.Motorcycle]: "updateDrivingHistoryItemLocationMc",
      [ServiceVehicleType.Moped]: "updateDrivingHistoryItemLocationAm",
    }[vehicleType];
    const updateDrivingHistoryItemLocation = this.fbFunctions.httpsCallable(
      "updateDrivingHistoryItemLocation"
    );
    const promise = updateDrivingHistoryItemLocation({
      studentFirebaseId,
      authorId: instructorId,
      key: itemKey,
      location,
    });
    return from(promise).pipe(switchMap(() => of(undefined)));
  }

  public getDrivingHistoryByBookingClientId$(
    bookingClientId: number,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<{ data: IDrivingHistoryItem; key: string }[]> {
    return from(
      getFirebaseIdByBookingClientId(bookingClientId, this.database)
    ).pipe(
      switchMap(firebaseId => {
        if (firebaseId) {
          return this.getDrivingHistory$(firebaseId, vehicleType);
        } else {
          return [];
        }
      })
    );
  }

  public getDrivingHistory$(
    studentFirebaseId: string,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<{ key: string; data: IDrivingHistoryItem }[]> {
    return new Observable<{ key: string; data: IDrivingHistoryItem }[]>(
      subscriber => {
        const errorHandler = (err: Error): void => {
          subscriber.error(err);
        };
        const handler = (snapshot: firebase.database.DataSnapshot): void => {
          const results: {
            key: string;
            data: IDrivingHistoryItem;
          }[] = [];
          snapshot.forEach(s => {
            const key = s.key;
            if (typeof key !== "string") {
              errorHandler(new Error("Key of drivingHistory is not a string"));
              return true;
            }
            const rawItem = s.val();
            const res = DrivingHistoryItem.decode(rawItem);
            if (either.isLeft(res)) {
              const error = toError(res);
              notification.error({
                description: JSON.stringify(rawItem),
                message: "Driving history item is damaged and skipped",
              });
              console.error(error);
              console.debug({ rawItem });
            } else {
              results.push({ key, data: res.right });
            }
          });
          subscriber.next(results);
        };

        const collectionNames = {
          [ServiceVehicleType.Car]: "userDrivingHistory",
          [ServiceVehicleType.Motorcycle]: "userDrivingHistoryMc",
          [ServiceVehicleType.Moped]: "userDrivingHistoryAm",
        };
        const collectionName = collectionNames[vehicleType];
        const ref = this.database.ref(
          `/v3/${collectionName}/${studentFirebaseId}`
        );

        ref.on("value", handler, errorHandler);

        return () => {
          ref.off("value", handler);
        };
      }
    );
  }

  public getUserDrivingPlanGroup$(
    studentFirebaseId: string,
    groupId: string,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<IUserDrivingPlanGroup | undefined> {
    return new Observable<IUserDrivingPlanGroup | undefined>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        if (val) {
          const res = UserDrivingPlanGroup.decode(val);
          if (either.isLeft(res)) {
            console.debug({ val, err: toError(res) });
            errorHandler(toError(res));
          } else {
            subscriber.next(res.right);
          }
        } else {
          subscriber.next(undefined);
        }
      };

      const collectionNames = {
        [ServiceVehicleType.Car]: "userDrivingPlans",
        [ServiceVehicleType.Motorcycle]: "userDrivingPlansMc",
        [ServiceVehicleType.Moped]: "userDrivingPlansAm",
      };
      const collectionName = collectionNames[vehicleType];
      const ref = this.database.ref(
        `/v3/${collectionName}/${studentFirebaseId}/groups/${groupId}`
      );
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }
  public getUserDrivingPlanGroupSummary$(
    studentFirebaseId: string,
    groupId: string,
    vehicleType: ServiceVehicleType = ServiceVehicleType.Car
  ): Observable<IUserDrivingPlanGroupSummary | undefined> {
    return new Observable<IUserDrivingPlanGroupSummary | undefined>(
      subscriber => {
        const errorHandler = (err: Error): void => {
          subscriber.error(err);
        };
        const handler = (snapshot: firebase.database.DataSnapshot): void => {
          const val = snapshot.val();
          if (val) {
            const res = UserDrivingPlanGroupSummary.decode(val);
            if (either.isLeft(res)) {
              console.debug({ val, err: toError(res) });
              errorHandler(toError(res));
            } else {
              subscriber.next(res.right);
            }
          } else {
            subscriber.next(undefined);
          }
        };

        const collectionNames = {
          [ServiceVehicleType.Car]: "userDrivingPlans",
          [ServiceVehicleType.Motorcycle]: "userDrivingPlansMc",
          [ServiceVehicleType.Moped]: "userDrivingPlansAm",
        };
        const collectionName = collectionNames[vehicleType];
        const ref = this.database.ref(
          `/v3/${collectionName}/${studentFirebaseId}/groupsSummary/${groupId}`
        );
        ref.on("value", handler, errorHandler);

        return () => {
          ref.off("value", handler);
        };
      }
    );
  }
  public createNote(studentId: string, note: string): Observable<void> {
    return from(createNewNote(studentId, note, this.database)).pipe(
      switchMap(() => of(undefined))
    );
  }
  public deleteNote(studentId: string): Observable<void> {
    return from(removeNote(this.database, studentId)).pipe(
      switchMap(() => of(undefined))
    );
  }
  public getNote(studentId: string): Observable<{ note: string }> {
    return new Observable<{ note: string }>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const results: {
          note: string;
        } = { note: "" };
        snapshot.forEach(s => {
          const firebaseId = s.key;
          if (typeof firebaseId !== "string") {
            errorHandler(new Error("Key of Users is not a string"));
            return true;
          }
          const rawNote = s.val();
          const res = Note.decode(rawNote);
          if (either.isLeft(res)) {
            console.warn(rawNote, toError(res));
          } else {
            results.note = res.right;
          }
        });
        subscriber.next(results);
      };

      const ref = this.database.ref(`/v3/notes/${studentId}`);
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }
  public signOut(studentId: string): Observable<void> {
    const endpoint = `${this.config.secApiUrl}/auth/webapp/token/${studentId}`;

    return makeCustomRequest(
      endpoint,
      null,
      "DELETE",
      t.unknown,
      false,
      this.fbAuth
    ).pipe(switchMap(() => of(undefined)));
  }
  public generateCode(phone: string): Observable<string> {
    const generateCode = this.fbFunctions.httpsCallable("generateCode");
    const promise = generateCode({
      phone,
    }).then(({ data }) => {
      console.log("done");
      return "" + data;
    });
    return from(promise);
  }

  public getSchoolGoals$(
    schoolId: string,
    firebaseId: string
  ): Observable<{
    goals: IInstructorGoals;
  }> {
    return new Observable<{
      goals: IInstructorGoals;
    }>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        let results: {
          goals: IInstructorGoals;
        } = { goals: {} };
        snapshot.forEach(s => {
          const month = s.key;
          if (typeof month !== "string") {
            errorHandler(new Error("Key of Goals is not a string"));
            return true;
          }
          const raw = s.val();
          const res = instructorGoalsOnMonth.decode(raw);
          if (isLeft(res)) {
            console.warn(raw, toError(res));
            errorHandler(toError(res));
          } else {
            results.goals[month] = res.right;
          }
        });
        subscriber.next(results);
      };
      const currentYear = new Date().getFullYear();
      const ref = this.database.ref(
        `/v3/instructorsGoals/${firebaseId}/${schoolId}/${currentYear}`
      );

      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    }).pipe(first());
  }

  public updateInstructorGoals(
    instructorId: string,
    schoolId: string,
    value: number,
    month: string
  ): Observable<void> {
    const ref = this.database.ref(
      `/v3/instructorsGoals/${instructorId}/${schoolId}/${new Date().getFullYear()}`
    );
    return from(ref.update({ [month]: value }));
  }
  public getClassInfo$(classId: string): Observable<IClassMemberInfo[]> {
    return new Observable<IClassMemberInfo[]>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        if (val) {
          const res = classRow.decode(val);

          if (isLeft(res)) {
            errorHandler(toError(res));
          } else {
            const res = Object.keys(val).map(bookingClientId => ({
              ...val[bookingClientId],
              bookingClientId,
            }));
            subscriber.next(Object.values(res));
          }
        } else {
          subscriber.next(undefined);
        }
      };

      const ref = this.database.ref(`/v3/classInfo/${classId}`);
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }
  public updateClassInfo(
    classId: string,
    bookingClientId: string,
    label: string,
    value: string | boolean
  ): Observable<void> {
    const ref = this.database.ref(
      `/v3/classInfo/${classId}/${bookingClientId}/`
    );
    return from(ref.update({ [label]: value }));
  }

  public putUserPhotoToStorage(file: RcFile): firebase.storage.UploadTask {
    const [fileName, fileExt] = file.name.split(".");
    const storage = firebase.storage();
    const storageRef = storage.ref("user_photos");
    const fileRef = storageRef.child(`${fileName}-${Date.now()}.${fileExt}`);
    return fileRef.put(file);
  }

  public getNotifyGlobalSettings$(): Observable<INotifyGlobalSettings> {
    return new Observable<INotifyGlobalSettings>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        if (val) {
          const res = NotifyGlobalSettings.decode(val);
          if (isLeft(res)) {
            console.debug({ val });
            errorHandler(toError(res));
          } else {
            subscriber.next(res.right);
          }
        } else {
          subscriber.next(undefined);
        }
      };

      const ref = this.database.ref(`/v3/settings/notifications/emails`);
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  // TODO: merge setAvailableForInstructor and setAvailableForStudent methods into one
  public setAvailableForInstructor(isAvailable: boolean): Observable<void> {
    const ref = this.database.ref(`/v3/settings/notifications/emails`);

    return from(
      ref.update({
        isAvailableForInstructor: isAvailable,
      })
    );
  }

  public setAvailableForStudent(isAvailable: boolean): Observable<void> {
    const ref = this.database.ref(`/v3/settings/notifications/emails`);

    return from(
      ref.update({
        isAvailableForStudent: isAvailable,
      })
    );
  }

  public getEventsTemplatesConfig$(
    type: "instructors" | "students"
  ): Observable<IEventsTemplatesConfig> {
    return new Observable<IEventsTemplatesConfig>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        if (val) {
          const res = EventsTemplatesConfig.decode(val);
          if (isLeft(res)) {
            console.debug({ val });
            errorHandler(toError(res));
          } else {
            subscriber.next(res.right);
          }
        } else {
          subscriber.next(undefined);
        }
      };

      const ref = this.database.ref(
        `/v3/settings/notifications/emails/eventsTemplatesConfiguration/${type}`
      );
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  public setEmailTemplateForEvent(
    type: "instructors" | "students",
    key: string,
    value: string
  ): Observable<void> {
    const ref = this.database.ref(
      `/v3/settings/notifications/emails/eventsTemplatesConfiguration/${type}/`
    );
    return from(ref.update({ [key]: value }));
  }

  public getEmailTemplates$(): Observable<IEmailTemplatesList> {
    const endpoint = `${this.config.notificationsApiUrl}/template/list`;

    return makeGetRequest$<IEmailTemplatesList>(
      endpoint,
      {},
      t.exact(EmailTemplatesList),
      this.fbAuth,
      true
    ).pipe(map(_ => _ || undefined));
  }

  public getEmailTemplate$(name: string): Observable<IEmailTemplate> {
    const endpoint = `${this.config.notificationsApiUrl}/template/${name}`;

    return makeGetRequest$<IEmailTemplate>(
      endpoint,
      {},
      t.exact(EmailTemplate),
      this.fbAuth,
      true
    ).pipe(map(_ => _ || undefined));
  }

  public createTemplate$(templateData: IEmailTemplate): Observable<void> {
    const endpoint = `${this.config.notificationsApiUrl}/template/`;

    return makeCustomRequest(
      endpoint,
      templateData,
      "POST",
      t.unknown,
      true,
      this.fbAuth
    ).pipe(switchMap(() => of(undefined)));
  }

  public updateTemplate$(templateData: IEmailTemplate): Observable<void> {
    const { templateName, ...payload } = templateData;
    const endpoint = `${this.config.notificationsApiUrl}/template/${templateName}`;

    return makeCustomRequest(
      endpoint,
      payload,
      "PUT",
      t.unknown,
      true,
      this.fbAuth
    ).pipe(switchMap(() => of(undefined)));
  }

  public deleteTemplate$(name: string): Observable<void> {
    const endpoint = `${this.config.notificationsApiUrl}/template/${name}`;

    return makeCustomRequest(
      endpoint,
      {},
      "DELETE",
      t.unknown,
      true,
      this.fbAuth
    ).pipe(switchMap(() => of(undefined)));
  }

  public getUser$(
    clientId: number,
    params?: IUsersSearch
  ): Observable<IUserV3> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/users/${clientId}`;
    return makeGetRequest$<IUserV3>(
      endpoint,
      { ...params },
      UserV3,
      this.fbAuth
    ).pipe(map(_ => _));
  }

  public getUserByAuthId$(authId: string): Observable<IUserV3 | undefined> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/users/search?authId=${authId}&withAssociationsIds=1&withLocations=1`; // NS FIX ME query
    // TODO It shouldn't be a search !!
    return makeGetRequest$<IUserV3[]>(
      endpoint,
      {},
      t.array(UserV3),
      this.fbAuth,
      false,
      true
    ).pipe(map(users => users[0]));
  }

  public getUsers$(params?: IUsersSearch): Observable<IUserV3[]> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/users/search`;
    return makeGetRequest$<IUserV3[]>(
      endpoint,
      { ...params },
      t.array(UserV3),
      this.fbAuth,
      false,
      true
    );
  }
}

async function removeNote(
  database: firebase.database.Database,
  studentId: string
): Promise<void> {
  const ref = database.ref(`/v3/notes/${studentId}`);

  return ref.remove();
}

async function createNewNote(
  studentId: string,
  note: string,
  fbDatabase: firebase.database.Database
): Promise<void> {
  return fbDatabase
    .ref(`/v3/notes/${studentId}`)
    .set({ note })
    .then(() => undefined);
}

async function getFirebaseIdByBookingClientId(
  bookingClientId: number,
  database: firebase.database.Database
): Promise<string | undefined> {
  const snapshot = await database
    .ref("/v3/users")
    .orderByChild("bookingClientId")
    .startAt(bookingClientId.toString())
    .limitToFirst(1)
    .once("value");
  // We're using startAt so we might receive another user, meaning that the required one wasn't found.
  // Hence the check for clientId equality. We're not using equalTo instead of startAt because in that
  // case once will void return.
  const usersDict:
    | { [key: string]: unknown }
    | null
    | undefined = snapshot.val();

  if (!usersDict) {
    return undefined;
  }
  const userKeys = Object.keys(usersDict);
  if (userKeys.length <= 0) {
    return undefined;
  }
  const firebaseId = userKeys[0];
  const val = usersDict[firebaseId];
  if (!val) {
    return undefined;
  }
  if ((val as any).bookingClientId === bookingClientId.toString()) {
    return firebaseId;
  } else {
    return undefined;
  }
}

async function getFirebaseIdBySSN(
  ssn: string,
  database: firebase.database.Database
): Promise<string | undefined> {
  const snapshot = await database
    .ref("/v3/users")
    .orderByChild("ssn")
    .startAt(ssn.toString())
    .limitToFirst(1)
    .once("value");
  // We're using startAt so we might receive another user, meaning that the required one wasn't found.
  // Hence the check for clientId equality. We're not using equalTo instead of startAt because in that
  // case once will void return.
  const usersDict:
    | { [key: string]: unknown }
    | null
    | undefined = snapshot.val();

  if (!usersDict) {
    return undefined;
  }
  const userKeys = Object.keys(usersDict);
  if (userKeys.length <= 0) {
    return undefined;
  }
  const firebaseId = userKeys[0];
  const val = usersDict[firebaseId];
  if (!val) {
    return undefined;
  }
  if ((val as any).ssn === ssn) {
    return firebaseId;
  } else {
    return undefined;
  }
}

export function makeCustomRequest<
  A,
  D extends Decoder<unknown, A> = Decoder<unknown, A>
>(
  endpoint: string,
  payload: unknown,
  method: "PUT" | "POST" | "DELETE",
  decoder: D,
  pure: boolean,
  fbAuth: firebase.auth.Auth
): Observable<A> {
  const user = fbAuth.currentUser;
  if (!user) {
    throw new Error("User must be signed in");
  }

  function makeRequest(token: string): Observable<Response> {
    const request = new Request(endpoint, {
      body: payload ? JSON.stringify(payload) : null,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method,
    });
    return fromFetch(request);
  }

  return getUserToken$(user).pipe(
    pure ? switchMap(makeRequest) : concatMap(makeRequest),
    switchMap(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.statusText);
      }
    }),
    map(response => {
      const res = decoder.decode(response);
      if (isLeft(res)) {
        throw toError(res);
      } else {
        return res.right;
      }
    })
  );
}
