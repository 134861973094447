/* eslint-disable @typescript-eslint/camelcase */
import * as t from "io-ts";
import { ISODate, nullable } from "../../io-ts";
import { ServiceVehicleType } from "../../entities/services/types";

const TranslatedField = t.type({
  en: t.string,
  sw: t.string,
});

export const ServiceV3 = t.intersection([
  t.type({
    id: t.number,
    name: TranslatedField,
    type: t.string,
    key: t.string,
    description: TranslatedField,
    image: nullable(t.string),
    alwaysAvailableSchedule: t.boolean,
    isSpecialSchedule: t.boolean,
    isActive: t.boolean,
    isVisible: t.boolean,
    priceWithVat: t.number,
    vehicleType: nullable(
      t.keyof({
        [ServiceVehicleType.Car]: null,
        [ServiceVehicleType.Motorcycle]: null,
        [ServiceVehicleType.Moped]: null,
      })
    ),
    duration: t.number,
    maxStudentsQty: t.number,
    createdAt: ISODate,
    updatedAt: ISODate,
    deletedAt: nullable(ISODate),
  }),
  t.partial({
    associationsIds: t.type({
      locations: t.array(t.number),
      instructors: t.array(t.number),
      regions: t.array(t.number),
      ownLocations: t.array(t.number),
    }),
    price: t.number,
    kkjOptions: nullable(
      t.type({
        priceRatio: t.number,
      })
    ),
  }),
]);

export interface IServiceV3 extends t.TypeOf<typeof ServiceV3> {
  //
}

export const DateScheduleSlotV3 = t.intersection([
  t.type({
    start: ISODate,
    end: ISODate,
  }),
  t.partial({
    applicableDomIdsList: t.array(t.string),
    options: t.partial({
      bondedUsersIds: t.array(t.number),
      comment: t.string,
      gearType: t.string,
      serviceConfig: t.partial({
        maxStudentsQty: t.number,
      }),
      isKkj: t.boolean,
    }),
  }),
]);
export const ApiClassScheduleV3 = t.record(
  t.string,
  t.record(t.string, t.array(DateScheduleSlotV3))
);

export interface IApiClassScheduleV3
  extends t.TypeOf<typeof ApiClassScheduleV3> {}
