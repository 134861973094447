import * as firebase from "firebase/app";
import { isLeft } from "fp-ts/lib/Either";
import { Decoder } from "io-ts";
import * as t from "io-ts";
import qs from "qs";
import { from, Observable, of, throwError } from "rxjs";
import { concatMap, map, switchMap } from "rxjs/operators";
import { bugsnagClient } from "../components/ErrorBoundary";
import { ApiResponse, ApiResponseV3 } from "../io-ts";
import { toError } from "./io-ts";

/**
 * from fetch from RxJS for some reason does not cancel request.
 * This one works fine
 */
export function fromFetch(input: string | Request): Observable<Response> {
  return new Observable<Response>(subscriber => {
    const controller = new AbortController();
    const signal = controller.signal;

    const init: RequestInit = { signal };

    let completed = false;
    fetch(input, init)
      .then(function gotResponseFromFetch(response) {
        subscriber.next(response);
        completed = true;
        subscriber.complete();
      })
      .catch(err => {
        completed = true;
        if (err instanceof DOMException && err.name === "AbortError") {
          subscriber.complete();
        } else {
          subscriber.error(err);
        }
      });

    return () => {
      if (!completed) {
        console.debug("abort request");
        controller.abort();
      }
    };
  });
}

export function getUserToken$(user: firebase.User): Observable<string> {
  return from(user.getIdToken());
}

/**
 *
 * @param response
 * @param decoder
 * @param customFormat - if it is true do not use ApiResponse codec
 * @param withPagination
 */
export function parseJsonResponse<
  A,
  D extends Decoder<unknown, A> = Decoder<unknown, A>
>(
  response: unknown,
  decoder: D,
  customFormat: boolean,
  withPagination?: boolean
): A {
  let data: unknown;
  if (customFormat) {
    data = response;
  } else if (withPagination) {
    const validator = ApiResponseV3(t.unknown);
    const result = validator.decode(response);
    if (isLeft(result)) {
      throw toError(result);
    }
    const apiResponse = result.right;
    if (apiResponse.errorCode) {
      throw new Error(`errorCode is "${apiResponse.errorCode}"`);
    }
    data = apiResponse.data.result;
  } else {
    const validator = ApiResponse(t.unknown);
    const result = validator.decode(response);
    if (isLeft(result)) {
      throw toError(result);
    }
    const apiResponse = result.right;
    // TODO: write custom type with reporter and check errorCode in the codec.
    if (apiResponse.errorCode) {
      throw new Error(`errorCode is "${apiResponse.errorCode}"`);
    }
    data = apiResponse.data;
  }
  const dataRes = decoder.decode(data);
  if (isLeft(dataRes)) {
    throw toError(dataRes);
  }
  return dataRes.right;
}

/**
 * Pure functions semantically is a GET request without side effects. Say "Hello" to the old back-end developer.
 * It means if pure=true, network request will be canceled after subscription is canceled.
 */
export function makePostJsonRequest$<
  A,
  D extends Decoder<unknown, A> = Decoder<unknown, A>
>(
  endpoint: string,
  payload: unknown,
  decoder: D,
  pure: boolean,
  fbAuth: firebase.auth.Auth,
  customResponse = false
): Observable<A> {
  const user = fbAuth.currentUser;
  if (!user) {
    throw new Error("User must be signed in");
  }

  function makeRequest(token: string): Observable<Response> {
    bugsnagClient.leaveBreadcrumb("makeRequest", {
      endpoint,
      payload,
    });
    const request = new Request(endpoint, {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    return fromFetch(request);
  }

  return getUserToken$(user).pipe(
    pure ? switchMap(makeRequest) : concatMap(makeRequest),
    switchMap(response => {
      if (response.ok) {
        return response.json();
      } else {
        return throwError(new Error(response.statusText));
      }
    }),
    map(response => {
      return parseJsonResponse<A>(response, decoder, customResponse);
    })
  );
}

export function makePostFormRequest$<
  A,
  D extends Decoder<unknown, A> = Decoder<unknown, A>
>(
  endpoint: string,
  payload: FormData,
  decoder: D,
  pure: boolean,
  fbAuth: firebase.auth.Auth,
  customResponse = true
): Observable<A> {
  const user = fbAuth.currentUser;
  if (!user) {
    throw new Error("User must be signed in");
  }

  function makeRequest(token: string): Observable<Response> {
    bugsnagClient.leaveBreadcrumb("makeRequest", {
      endpoint,
      payload,
    });
    const request = new Request(endpoint, {
      body: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
    });
    return fromFetch(request);
  }

  return getUserToken$(user).pipe(
    pure ? switchMap(makeRequest) : concatMap(makeRequest),
    switchMap(response => {
      if (response.ok) {
        return response.json();
      } else {
        return throwError(new Error(response.statusText));
      }
    }),
    map(response => {
      return parseJsonResponse<A>(response, decoder, customResponse);
    })
  );
}

export function makePutJsonRequest$<
  A,
  D extends Decoder<unknown, A> = Decoder<unknown, A>
>(
  endpoint: string,
  payload: unknown,
  decoder: D,
  fbAuth: firebase.auth.Auth,
  customResponse = false
): Observable<A> {
  const user = fbAuth.currentUser;
  if (!user) {
    throw new Error("User must be signed in");
  }

  function makeRequest(token: string): Observable<Response> {
    const request = new Request(endpoint, {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
    });
    return fromFetch(request);
  }

  return getUserToken$(user).pipe(
    concatMap(makeRequest),
    switchMap(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.statusText);
      }
    }),
    map(response => {
      return parseJsonResponse<A>(response, decoder, customResponse);
    })
  );
}

export function makeGetRequest$<
  A,
  D extends Decoder<unknown, A> = Decoder<unknown, A>
>(
  endpoint: string,
  query: {
    [key: string]:
      | string
      | string[]
      | number
      | number[]
      | { [key: string]: string | number };
  },
  decoder: D,
  fbAuth: firebase.auth.Auth,
  customResponse = false,
  v3API = false,
  isNullable = false
): Observable<A> {
  const user = fbAuth.currentUser;
  if (!user) {
    throw new Error("User must be signed in");
  }

  function makeRequest(token: string): Observable<Response> {
    bugsnagClient.leaveBreadcrumb("makeGetRequest$", {
      endpoint,
      query,
    });

    // checking if query is empty to avoid adding the "?" symbol at the end of a request url
    // "?" without a query after it may fail some requests
    const queryString = Object.keys(query).length
      ? `?${qs.stringify(query, {
          arrayFormat: "brackets",
          encode: false,
        })}`
      : "";
    const request = new Request(`${endpoint}${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    return fromFetch(request);
  }

  return getUserToken$(user).pipe(
    switchMap(makeRequest),
    switchMap(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (isNullable) {
          // the worst hack
          return of({ data: null, errorCode: 0 });
        }
        throw new Error(response.statusText);
      }
    }),
    map(response => {
      return parseJsonResponse<A>(response, decoder, customResponse, v3API);
    })
  );
}
export function getBlob$(
  endpoint: string,
  query: { [key: string]: string | number | string[] | undefined },
  fbAuth: firebase.auth.Auth
): Observable<Blob> {
  const user = fbAuth.currentUser;
  if (!user) {
    throw new Error("User must be signed in");
  }

  function makeRequest(token: string): Observable<Response> {
    const request = new Request(`${endpoint}?${qs.stringify(query)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/octet-stream",
      },
      method: "GET",
    });
    return fromFetch(request);
  }

  return getUserToken$(user).pipe(
    switchMap(makeRequest),
    switchMap(response => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error(response.statusText);
      }
    }),
    map(response => {
      return response;
    })
  );
}
