import "firebase/auth";
import React from "react";
import { FC, useContext } from "react";
import { bindUseRequiredContext, useComputed } from "../../../hooks";
import { useFirebase } from "../provider";
import { FirebaseAuthContext } from "./context";

export const FirebaseAuthProvider: FC = props => {
  const upperDefinedFirebase = useContext(FirebaseAuthContext);
  if (upperDefinedFirebase) {
    throw new Error(
      "Currently we supports only one FirebaseAuthProvider in the tree"
    );
  }
  const firebaseApp = useFirebase();
  const auth = useComputed(() => firebaseApp.auth(), [firebaseApp]);

  return (
    <FirebaseAuthContext.Provider value={auth}>
      {props.children}
    </FirebaseAuthContext.Provider>
  );
};

export const useFirebaseAuth = bindUseRequiredContext(FirebaseAuthContext);
