import React, { createContext, FC } from "react";
import { config } from "../../config";
import { bindUseRequiredContext, useComputed } from "../../hooks";
import { useFirebaseAuth } from "../../modules/firebase/auth/provider";
import { useFirebaseDatabase } from "../../modules/firebase/database/provider";
import { useFirebaseFunctions } from "../../modules/firebase/functions/provider";
import { useFirebaseStorage } from "../../modules/firebase/storage/provider";
import { UsersService } from "./index";
import { IUsersService } from "./types";

export const UsersServiceContext = createContext<IUsersService | undefined>(
  undefined
);
UsersServiceContext.displayName = "UsersServiceContext";
export const UsersServiceProvider: FC = props => {
  const fbAuth = useFirebaseAuth();
  const firebaseFunctions = useFirebaseFunctions();
  const firebaseDatabase = useFirebaseDatabase();
  const firebaseStorage = useFirebaseStorage();
  const usersService = useComputed(
    () =>
      new UsersService(
        firebaseDatabase,
        firebaseStorage,
        fbAuth,
        firebaseFunctions,
        config
      ),
    [firebaseDatabase, fbAuth, firebaseFunctions, config]
  );

  return (
    <UsersServiceContext.Provider value={usersService}>
      {props.children}
    </UsersServiceContext.Provider>
  );
};

export const useUsersService = bindUseRequiredContext(UsersServiceContext);
