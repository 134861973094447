import * as firebase from "firebase/app";
import { IConfig } from "../../config/io-ts";
import { extractTrueKeys } from "../../helpers/array";
import { InstructorStudents } from "../UsersService/io-ts";
import { ISchoolsService } from "./types";
import { combineLatest, from, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { DrivingSchoolInfo, IDrivingSchoolInfo } from "./io-ts";
import { toError } from "../../helpers/io-ts";
import { either } from "fp-ts";

export class SchoolsService implements ISchoolsService {
  protected readonly fbDatabase: firebase.database.Database;
  protected readonly fbAuth: firebase.auth.Auth;
  protected readonly fbFunctions: firebase.functions.Functions;
  protected readonly config: IConfig;
  constructor(
    fbDatabase: firebase.database.Database,
    fbAuth: firebase.auth.Auth,
    fbFunctions: firebase.functions.Functions,
    config: IConfig
  ) {
    this.fbDatabase = fbDatabase;
    this.fbAuth = fbAuth;
    this.fbFunctions = fbFunctions;
    this.config = config;
  }

  public createSchool(schoolInfo: IDrivingSchoolInfo): Observable<void> {
    return from(createNewSchool(schoolInfo, this.fbDatabase)).pipe(
      switchMap(() => of(undefined))
    );
  }

  public getAllAvailableStudentsN$(instructorId: number): Observable<number[]> {
    return this.getAllAvailableStudentsN$(instructorId).pipe(
      switchMap(hasAccessToInstructorsN =>
        combineLatest(
          hasAccessToInstructorsN.map(instructorId =>
            this.getInstructorStudents$(instructorId)
          )
        )
      ),
      map(chunks => Array.from(new Set(chunks.flat())))
    );
  }

  public getInstructorStudents$(instructorId: number): Observable<number[]> {
    return new Observable<number[]>(subscriber => {
      const errorHandler = (err: Error): void => {
        console.error(err);
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const raw = snapshot.val();
        if (!raw) {
          subscriber.next([]);
          return;
        }
        const res = InstructorStudents.decode(raw);
        if (either.isLeft(res)) {
          console.error("getInstructorStudents$", { instructorId, raw });
          errorHandler(toError(res));
          return;
        } else {
          const students = extractTrueKeys(res.right);
          subscriber.next(students.map(x => Number(x)));
        }
      };

      const ref = this.fbDatabase.ref(`/v3/instructorStudents/${instructorId}`);

      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  public deleteSchool(firebaseId: string): Observable<void> {
    return from(removeSchool(this.fbDatabase, firebaseId));
  }

  public updateSchoolInfo(
    firebaseId: string,
    schoolInfo: IDrivingSchoolInfo
  ): Observable<void> {
    const ref = this.fbDatabase.ref(`/v3/school/${firebaseId}`);
    return from(ref.update(DrivingSchoolInfo.encode(schoolInfo)));
  }

  public getSchoolsByInstructorId$(
    firebaseId: string
  ): Observable<{ asOwner: string[]; asInstructor: string[] }> {
    return this.getAllSchools$().pipe(
      map(schools => {
        return {
          asOwner: schools
            .map(sch => (sch.data.owners[firebaseId] ? sch.schoolId : ""))
            .filter(Boolean),
          asInstructor: schools
            .map(sch => (sch.data.instructors[firebaseId] ? sch.schoolId : ""))
            .filter(Boolean),
        };
      })
    );
  }

  public getAllSchools$(): Observable<
    { schoolId: string; data: IDrivingSchoolInfo }[]
  > {
    return new Observable<{ schoolId: string; data: IDrivingSchoolInfo }[]>(
      subscriber => {
        const errorHandler = (err: Error): void => {
          subscriber.error(err);
        };
        const handler = (snapshot: firebase.database.DataSnapshot): void => {
          const results: {
            schoolId: string;
            data: IDrivingSchoolInfo;
          }[] = [];
          snapshot.forEach(s => {
            const firebaseId = s.key;
            if (typeof firebaseId !== "string") {
              errorHandler(new Error("Key of Users is not a string"));
              return true;
            }
            const rawSchool = s.val();
            const res = DrivingSchoolInfo.decode(rawSchool);
            if (either.isLeft(res)) {
              console.warn(rawSchool, toError(res));
            } else {
              results.push({ schoolId: firebaseId, data: res.right });
            }
          });
          subscriber.next(results);
        };

        const ref = this.fbDatabase.ref(`/v3/school/`);
        ref.on("value", handler, errorHandler);

        return () => {
          ref.off("value", handler);
        };
      }
    );
  }
  public getSchoolById$(
    schoolId: string
  ): Observable<{ schoolId: string; data: IDrivingSchoolInfo }> {
    return new Observable<{ schoolId: string; data: IDrivingSchoolInfo }>(
      subscriber => {
        const errorHandler = (err: Error): void => {
          subscriber.error(err);
        };
        const handler = (snapshot: firebase.database.DataSnapshot): void => {
          let results: {
            schoolId: string;
            data: IDrivingSchoolInfo;
          } = { schoolId: "", data: ({} as any) as IDrivingSchoolInfo };
          const schoolId = snapshot.key;
          if (typeof schoolId !== "string") {
            errorHandler(new Error("Key of Users is not a string"));
            return;
          }
          const rawSchool = snapshot.val();
          const res = DrivingSchoolInfo.decode(rawSchool);
          if (either.isLeft(res)) {
            console.warn(rawSchool, toError(res));
          } else {
            results = { schoolId, data: res.right };
          }
          subscriber.next(results);
        };

        const ref = this.fbDatabase.ref(`/v3/school/${schoolId}`);
        ref.on("value", handler, errorHandler);

        return () => {
          ref.off("value", handler);
        };
      }
    );
  }

  public getHasAccessToInstructors$(uid: string): Observable<string[]> {
    return this.getAllSchools$().pipe(
      map(schools =>
        schools
          .map(school => {
            const owners = extractTrueKeys(school.data.owners);
            const isOwner = owners.some(ownerId => ownerId === uid);
            if (isOwner) {
              const hiddenInstructors = school.data.hiddenInstructors
                ? extractTrueKeys(school.data.hiddenInstructors)
                : [];
              return [
                ...extractTrueKeys(school.data.instructors),
                ...hiddenInstructors,
              ];
            } else {
              return [];
            }
          })
          .flat(1)
      ),
      map(uids => Array.from(new Set([...uids, uid])))
    );
  }
}
async function createNewSchool(
  schoolInfo: IDrivingSchoolInfo,
  fbDatabase: firebase.database.Database
): Promise<void> {
  return fbDatabase
    .ref(`/v3/school/`)
    .push(schoolInfo)
    .then(() => undefined);
}

async function removeSchool(
  database: firebase.database.Database,
  firebaseId: string
): Promise<void> {
  const ref = database.ref(`/v3/school/${firebaseId}`);

  return ref.remove();
}
