import { isLeft } from "fp-ts/lib/Either";
import { toError } from "../helpers/io-ts";
import { Config, IConfig } from "./io-ts";

declare const CONFIG: IConfig;

function getConfig(): IConfig {
  const res = Config.decode(CONFIG);
  if (isLeft(res)) {
    throw toError(res);
  } else {
    return res.right;
  }
}

export const config = getConfig();
