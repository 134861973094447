import { ITranslatedField } from "../../api/types";
import { ILocation } from "../locations/types";
import { IRegion } from "../regions/types";
import { IUser } from "../users/types";
import { IPeriod } from "../../pages/Schedules/Common/types";
import { ProductType } from "../../api/v3/bundles/types";
import { IColours } from "../../pages/Services/modals/components/Form";

//FIXME rename and move
export type ServiceType = "lesson" | "class";

export enum ServiceVehicleType {
  Car = "car",
  Motorcycle = "mc",
  Moped = "moped",
}

export enum LanguageCodes {
  English = "en",
  Swedish = "sv",
}

export interface IRequestServicePayload {
  name: ITranslatedField;
  description: ITranslatedField;
  type: ServiceType;
  image?: string | null;
  isActive?: boolean;
  isVisible?: boolean;
  price?: number;
  priceWithVat: number;
  duration: number;
  maxStudentsQty: number;
  associationsIds?: {
    regions?: number[];
    locations?: number[];
    instructors?: number[];
  };
  key: string;
  packageLessonsQty: number | null;
  kkjOptions?: {
    priceRatio: number; // positive; min = 0.5; max = 10;
  } | null;
  languageCode?: LanguageCodes;
  options?: IServiceOptions;
}
export interface IServiceOptions {
  colours?: IColours;
  automaticBooking?: boolean;
}

export interface IEditServiceRequestPayload {
  id: number;
  values: IRequestServicePayload;
}

export interface IRegionProductOrder {
  productId: number;
  productType: string;
}

export interface IService {
  id: number;
  name: ITranslatedField;
  type: ServiceType;
  vehicleType: ServiceVehicleType;
  key: string;
  description: ITranslatedField;
  isActive: boolean;
  isVisible: boolean;
  price?: number;
  priceWithVat: number;
  duration: number;
  maxStudentsQty: number;
  domId: string;
  associationsIds?: {
    regions?: number[];
    ownLocations?: number[];
    locations?: number[];
    instructors?: number[];
  };
  locations?: ILocation[];
  regions?: IRegion[];
  instructors?: IUser[];
  packageLessonsQty: number | null;
  inRegionsSortingIndexes: {
    [key: string]: number;
  };
  kkjOptions?: {
    priceRatio: number; // positive; min = 0.5; max = 10;
  };
  isExchangeable?: boolean;
  exchangeableLocationsToServicesMapping?: {
    [key: number]: number[];
  };
  languageCode: LanguageCodes;
  productType?: ProductType;
  options?: IServiceOptions;
}

export interface IServiceConfig {
  key: string;
  packageLessonsQty: number;
  isActive: boolean;
}

export interface IServiceWithLocation extends IService {
  location: ILocation;
}

export interface IUseServiceSchedulePayload extends IPeriod {
  serviceId: number;
}

enum TheoryServiceType {
  Bonniers = "Bonniers",
  MDA = "MDA",
}

export interface ITheory {
  id: number;
  domId: string;
  name: ITranslatedField;
  description: ITranslatedField;
  price: number;
  priceWithVat: number;
  type: TheoryServiceType;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}
