import { McLessonType } from "src/pages/Schedules/Common/types";
import { ICoreApiBooking } from "../../api/v3/bookings/types";
import { ServiceVehicleType } from "../services/types";
import { IUser } from "../users/types";

export enum GearType {
  Manual = "manual",
  Automatic = "automatic",
}

export enum GearTypeMc {
  McA = "mcA",
  McA1 = "mcA1",
  McA2 = "mcA2",
}

// FIXME: There shouldn't be lowercase keys
export enum MultipleGearType {
  McAMcA2 = "mcA,mcA2",
}

export const GearTypeMcLabel: {
  [key in GearTypeMc | keyof typeof GearTypeMc]: string;
} = {
  McA: "A",
  McA1: "A1",
  McA2: "A2",
  mcA: "A",
  mcA1: "A1",
  mcA2: "A2",
};

export interface IVehicle {
  id: number;
  name: string;
  description: string;
  plateInfo: string;
  isAvailable: boolean;
  gearType: GearType | GearTypeMc;
  users?: IUser[];
  locationId: number | null;
}

export interface IRequestVehiclePayload
  extends Pick<
    IVehicle,
    | "name"
    | "description"
    | "plateInfo"
    | "gearType"
    | "isAvailable"
    | "locationId"
  > {}

export interface IVehicleForm extends IRequestVehiclePayload {
  isShareable?: boolean;
}

export interface IUpdateVehiclePayload {
  id: number;
  values: IRequestVehiclePayload;
}

export interface ICarsPeriod {
  from?: string;
  to?: string;
}
export interface IVehicleMaintanceSlotPayload {
  id: number;
  from: string;
  to: string;
}
export interface IMainenanceSlotsByInstructorPayload {
  vehicleIds: number[];
  from: string;
  to: string;
}
export interface IVehicleScheduleByInstructorPayload {
  id: number[];
  from: string;
  to: string;
}
export interface ISwapVehiclesPayload {
  sourceId: number;
  targetId: number;
}
export interface IVehicleMaintenanceSlotData {
  start: Date | string;
  end: Date | string;
  applicableDomIdsList?: string[];
  options?: {
    isKkj?: boolean;
    bondedUsersIds?: number[];
    comment?: string;
    gearType?: string;
    vehicleType?: ServiceVehicleType; // was accidentally added by backend to all the existing slots and should be removed (MDAP-3167)
  };
  vehicleId?: number;
  domId?: string;
  groupId?: string;
  booking?: ICoreApiBooking;
}
export interface IApiVehicleMaintenanceSlot {
  start: string;
  end: string;
  applicableDomIdsList?: string[];
  options?: IOptions;
}

export interface IOptions {
  isKkj?: boolean;
  bondedUsersIds?: number[];
  comment?: string;
  gearType?: string;
}
export interface ICarMaintenancePayload {
  slots?: IVehicleMaintenanceSlotData[];
  from: string;
  to: string;
}
