export function toObject<
  T extends readonly string[],
  V extends (key: string) => V
>(keys: T, value: V): Record<string, V>;
export function toObject<T extends readonly string[], V = unknown>(
  keys: T,
  value: V
): Record<string, V>;
export function toObject<T extends readonly string[], V>(
  keys: T,
  value: V
): Record<string, V> {
  if (typeof value === "function") {
    return keys.reduce((previousValue: Record<string, V>, currentValue) => {
      previousValue[currentValue] = value(currentValue);
      return previousValue;
    }, {});
  } else {
    return keys.reduce((previousValue: Record<string, V>, currentValue) => {
      previousValue[currentValue] = value;
      return previousValue;
    }, {});
  }
}

export function extractTrueKeys(record: Record<string, boolean>): string[] {
  return Object.entries(record)
    .filter(([, value]) => !!value)
    .map(([key]) => key);
}

export function isFoundPredicate<T>(t: T | undefined): t is T {
  return t !== undefined;
}
