import * as firebase from "firebase";
import { isLeft } from "fp-ts/lib/Either";
import * as t from "io-ts";
import { Observable, from, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IConfig } from "../../config/io-ts";
import { toDateStr } from "../../helpers/datetime";
import {
  makeGetRequest$,
  makePostJsonRequest$,
  makePutJsonRequest$,
} from "../../helpers/http";
import { toError } from "../../helpers/io-ts";

import { IOrderHistoryV3, OrderHistoryV3, ScheduleV3 } from "../../io-ts";
import { makeCustomRequest } from "../UsersService";
import {
  IStudentDashboard,
  StudentDashboard,
  IUserExam,
  UserExam,
  IDbBooking,
  DbBooking,
  UserExamType,
  UserExamResult,
  IApiStudentInfo,
  ApiStudentInfo,
  IPurgeClientResult,
  PurgeClientResult,
  IApiBookingV3,
  ApiBookingV3,
} from "./io-ts";
import { IBookingsService, ISchedule } from "./types";
import { ServiceType, ServiceVehicleType } from "../../entities/services/types";
import qs from "qs";
import { BinaryAsBooleanValues } from "../../api/types";

async function deleteExam(
  database: firebase.database.Database,
  studentId: number,
  examId: string
): Promise<void> {
  const ref = database.ref(`/v3/userExamsTree/${studentId}/${examId}`);

  return ref.remove();
}

export class BookingsService implements IBookingsService {
  protected readonly fbAuth: firebase.auth.Auth;
  protected readonly config: IConfig;
  protected readonly fbDatabase: firebase.database.Database;
  constructor(
    fbAuth: firebase.auth.Auth,
    config: IConfig,
    fbDatabase: firebase.database.Database
  ) {
    this.fbAuth = fbAuth;
    this.config = config;
    this.fbDatabase = fbDatabase;
  }

  public fetchInstructorBookingsV3(
    instructorId: number,
    dateFrom: Date,
    dateTo: Date
  ): Observable<IApiBookingV3[]> {
    const endpoint = `${
      this.config.mdaCoreApiUrl
    }/v3/bookings/search?instructorId=${instructorId}&startDate[from]=${toDateStr(
      dateFrom
    )}&endDate[from]=${toDateStr(dateFrom)}&endDate[to]=${toDateStr(dateTo)}`;

    return makeGetRequest$(
      endpoint,
      {},
      t.array(t.exact(ApiBookingV3)),
      this.fbAuth,
      false,
      true
    );
  }

  public fetchClassesV3(
    dateFrom: Date,
    dateTo: Date,
    options?: {
      isPaid?: BinaryAsBooleanValues;
    }
  ): Observable<IApiBookingV3[]> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/bookings/search/`;
    const payload: {
      startDate: {
        from: string;
        to: string;
      };
      isPaid?: BinaryAsBooleanValues;
      isClass: BinaryAsBooleanValues;
    } = {
      startDate: {
        from: toDateStr(dateFrom),
        to: toDateStr(dateTo),
      },
      isPaid: options?.isPaid,
      isClass: BinaryAsBooleanValues.True,
    };

    return makeGetRequest$<IApiBookingV3[]>(
      endpoint,
      payload,
      t.array(ApiBookingV3),
      this.fbAuth,
      false,
      true
    ).pipe(map(_ => _));
  }
  public getStudentBookings$(studentId: number): Observable<IDbBooking[]> {
    return new Observable<IDbBooking[]>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const val = snapshot.val();
        const res = t
          .union([t.null, t.array(DbBooking), t.record(t.string, DbBooking)])
          .decode(val);
        if (isLeft(res)) {
          errorHandler(toError(res));
        } else {
          const bookingsUnsafe = res.right;
          let bookings: IDbBooking[];
          if (!bookingsUnsafe) {
            bookings = [];
          } else if (Array.isArray(bookingsUnsafe)) {
            bookings = bookingsUnsafe;
          } else {
            bookings = Array.from(Object.values(bookingsUnsafe));
          }

          subscriber.next(bookings);
        }
      };

      const ref = this.fbDatabase.ref(`/v3/userBookingsTree/${studentId}`);

      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  public getAllStudentBookings$(
    studentId: number,
    instructorId: number
  ): Observable<IDbBooking[]> {
    return new Observable<IDbBooking[]>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const result: any[] = [];

        snapshot.forEach(s => {
          const key = s.key;
          if (typeof key !== "string") {
            errorHandler(new Error("Key of exam is not a string"));
            return true;
          }
          const val = s.val();
          const res = t.union([t.null, DbBooking]).decode(val);
          if (isLeft(res)) {
            console.warn("warn", studentId);
          } else {
            if (res.right) {
              const canceledBooking =
                !res.right.bookingType || res.right.bookingType === "cancel";
              const alienBooking =
                !res.right.unitId || Number(res.right.unitId) !== instructorId;

              if (!canceledBooking && !alienBooking) {
                result.push(res.right);
              }
            }
          }
        });
        subscriber.next(result);
      };

      const ref = this.fbDatabase.ref(`/v3/userBookingsTree/${studentId}`);

      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  public fetchStudentBookingsV3(
    studentId: number
  ): Observable<IApiBookingV3[]> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/bookings/search/?clientId=${studentId}`;

    return makeGetRequest$<IApiBookingV3[]>(
      endpoint,
      {},
      t.array(t.exact(ApiBookingV3)),
      this.fbAuth,
      false,
      true
    ).pipe(map(_ => _.filter(x => x)));
  }

  public fetchDashboardV3(clientId: number): Observable<IStudentDashboard> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/clients/${clientId}/dashboard`;
    return makeGetRequest$(endpoint, {}, StudentDashboard, this.fbAuth);
  }

  public getStudentExams$(
    clientId: number
  ): Observable<{ key: string; data: IUserExam }[]> {
    return new Observable<{ key: string; data: IUserExam }[]>(subscriber => {
      const errorHandler = (err: Error): void => {
        subscriber.error(err);
      };
      const handler = (snapshot: firebase.database.DataSnapshot): void => {
        const exams: { key: string; data: IUserExam }[] = [];
        snapshot.forEach(s => {
          const key = s.key;
          if (typeof key !== "string") {
            errorHandler(new Error("Key of exam is not a string"));
            return true;
          }
          const rawExam = s.val();
          const res = UserExam.decode(rawExam);
          if (isLeft(res)) {
            errorHandler(toError(res));
            return true;
          } else {
            exams.push({ key, data: res.right });
          }
        });
        subscriber.next(exams);
      };

      const ref = this.fbDatabase.ref(`/v3/userExamsTree/${clientId}`);
      ref.on("value", handler, errorHandler);

      return () => {
        ref.off("value", handler);
      };
    });
  }

  public createExam$(studentId: number, exam: IUserExam): Observable<void> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v2/exam`;

    const encodedExam = UserExam.encode(exam);

    const payload: {
      bookingClientId: string;
      id: "";
      bookingId: false | string | readonly string[];
      comment?: string;
      date: string;
      endTime: string;
      result: UserExamResult;
      startTime: string;
      type: UserExamType;
    } = {
      ...encodedExam,
      bookingClientId: studentId.toString(),
      id: "",
    };

    return makePostJsonRequest$(
      endpoint,
      payload,
      t.unknown,
      false,
      this.fbAuth,
      true
    ).pipe(switchMap(() => of(undefined)));
  }

  public createExamV3$(studentId: number, exam: IUserExam): Observable<void> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/exams/client/${studentId}`;

    const encodedExam = UserExam.encode(exam);
    const { bookingId, date, endTime, startTime, ...rest } = encodedExam;
    const bookingsIds = Array.isArray(encodedExam.bookingId)
      ? encodedExam.bookingId.map(Number)
      : encodedExam.bookingId
      ? [Number(encodedExam.bookingId)]
      : [];
    const startDate = new Date(`${encodedExam.date} ${encodedExam.startTime}`);
    const startDateTimezoneOffset = startDate.getTimezoneOffset() * 60000; // Get timezone offset in milliseconds
    const endDate = new Date(`${encodedExam.date} ${encodedExam.startTime}`);
    const endDateTimezoneOffset = endDate.getTimezoneOffset() * 60000;
    const startTs =
      new Date(`${encodedExam.date} ${encodedExam.startTime}`).getTime() -
      startDateTimezoneOffset;
    const endTs =
      new Date(`${encodedExam.date} ${encodedExam.endTime}`).getTime() -
      endDateTimezoneOffset;

    const payload: {
      clientId: number;
      comment?: string;
      startTs: number;
      endTs: number;
      result: UserExamResult;
      type: UserExamType;
      bookingsIds?: number[];
      vehicleType?: ServiceVehicleType;
    } = {
      ...rest,
      clientId: studentId,
      bookingsIds,
      startTs,
      endTs,
    };

    return makePostJsonRequest$(
      endpoint,
      payload,
      t.unknown,
      false,
      this.fbAuth,
      true
    ).pipe(switchMap(() => of(undefined)));
  }

  public deleteExam$(studentId: number, examId: string): Observable<void> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/exams/client/${studentId}/${examId}`;
    return makeCustomRequest(
      endpoint,
      null,
      "DELETE",
      t.unknown,
      false,
      this.fbAuth
    ).pipe(switchMap(() => of(undefined)));
  }

  public updateExam(
    studentId: number,
    examId: string,
    exam: IUserExam
  ): Observable<void> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v2/exam`;

    const encodedExam = UserExam.encode(exam);

    const payload: {
      bookingClientId: string;
      examId: string;
      id: "";
      bookingId: false | string | readonly string[];
      comment?: string;
      date: string;
      endTime: string;
      result: UserExamResult;
      startTime: string;
      type: UserExamType;
    } = {
      ...encodedExam,
      bookingClientId: studentId.toString(),
      examId,
      id: "",
    };

    return makePutJsonRequest$(
      endpoint,
      payload,
      t.unknown,
      this.fbAuth,
      true
    ).pipe(switchMap(() => of(undefined)));
  }

  public updateExamV3(
    studentId: number,
    examId: string,
    exam: IUserExam
  ): Observable<void> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/exams/client/${studentId}/${examId}`;

    const encodedExam = UserExam.encode(exam);
    const {
      bookingId,
      date,
      endTime,
      startTime,
      updatedAt,
      ...rest
    } = encodedExam;
    const bookingsIds = Array.isArray(encodedExam.bookingId)
      ? encodedExam.bookingId.map(Number)
      : encodedExam.bookingId
      ? [Number(encodedExam.bookingId)]
      : [];
    const startDate = new Date(`${encodedExam.date} ${encodedExam.startTime}`);
    const startDateTimezoneOffset = startDate.getTimezoneOffset() * 60000; // Get timezone offset in milliseconds
    const endDate = new Date(`${encodedExam.date} ${encodedExam.startTime}`);
    const endDateTimezoneOffset = endDate.getTimezoneOffset() * 60000;
    const startTs =
      new Date(`${encodedExam.date} ${encodedExam.startTime}`).getTime() -
      startDateTimezoneOffset;
    const endTs =
      new Date(`${encodedExam.date} ${encodedExam.endTime}`).getTime() -
      endDateTimezoneOffset;

    const payload: {
      clientId: number;
      examId: string;
      bookingsIds: number[];
      comment?: string;
      result: UserExamResult;
      type: UserExamType;
      startTs: number;
      endTs: number;
      vehicleType?: ServiceVehicleType;
    } = {
      ...rest,
      clientId: studentId,
      bookingsIds,
      startTs,
      endTs,
      examId,
    };

    return makePutJsonRequest$(
      endpoint,
      payload,
      t.unknown,
      this.fbAuth,
      true
    ).pipe(switchMap(() => of(undefined)));
  }

  public updateExamResult(
    studentId: number,
    examId: string,
    result: UserExamResult
  ): Observable<void> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v2/exam/result`;

    const payload: {
      bookingClientId: string;
      examId: string;
      result: UserExamResult;
    } = {
      bookingClientId: studentId.toString(),
      examId,
      result,
    };
    return makePutJsonRequest$(
      endpoint,
      payload,
      t.unknown,
      this.fbAuth,
      true
    ).pipe(switchMap(() => of(undefined)));
  }

  public refund$(studentBookingId: number, comment: string): Observable<void> {
    const endpoint = `${this.config.apiUrl}/simplybook/refunds`;
    const payload: {
      bookingClientId: string;
      comment?: string;
    } = { bookingClientId: studentBookingId.toString(), comment: comment };
    return makePostJsonRequest$(
      endpoint,
      payload,
      t.unknown,
      false,
      this.fbAuth
    ).pipe(switchMap(() => of(undefined)));
  }

  public getOrdersV3(studentId: number): Observable<IOrderHistoryV3[]> {
    const user = this.fbAuth.currentUser;
    if (!user) {
      throw new Error("User must be signed in");
    }

    const endpoint = `${this.config.mdaCoreApiUrl}/v3/clients/${studentId}/orders/history`;
    return makeGetRequest$(
      endpoint,
      {},
      t.array(OrderHistoryV3),
      this.fbAuth,
      false
    );
  }

  public getAllAvailableSlotsV3(
    dateFrom: Date,
    dateTo: Date,
    type: ServiceType,
    query?: {
      servicesIds?: number[];
      locationsIds?: number[];
    }
  ): Observable<ISchedule> {
    const user = this.fbAuth.currentUser;
    if (!user) {
      throw new Error("User must be signed in");
    }
    const queryString = qs.stringify(query, {
      arrayFormat: "brackets",
      encode: false,
    });
    const endpoint = `${
      this.config.mdaCoreApiUrl
    }/v3/availableSlots/from/${toDateStr(dateFrom)}/to/${toDateStr(
      dateTo
    )}/services/type/${type}/?${queryString && queryString}`;

    return makeGetRequest$(
      endpoint,
      {}, // NS FIXME query
      ScheduleV3,
      this.fbAuth,
      false
    );
  }

  public getStudentInfo$(phoneNumber: string): Observable<IApiStudentInfo> {
    const endpoint = `${this.config.apiUrl}/v2/client/purge/${phoneNumber}`;

    return makeGetRequest$(endpoint, {}, ApiStudentInfo, this.fbAuth);
  }

  public deleteUser(phoneNumber: string): Observable<IPurgeClientResult> {
    const endpoint = `${this.config.apiUrl}/v2/client/purge/${phoneNumber}`;
    return makeCustomRequest(
      endpoint,
      null,
      "DELETE",
      PurgeClientResult,
      false,
      this.fbAuth
    );
  }

  public makeRefundV3$(
    clientId: number,
    orderId: string,
    qty: number,
    comment: string
  ): Observable<void> {
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/refunds`;
    const payload: {
      clientId: number;
      orderId: string;
      qty: number;
      comment: string;
    } = {
      clientId,
      orderId,
      qty,
      comment,
    };

    return makePostJsonRequest$<unknown>(
      endpoint,
      payload,
      t.unknown,
      false,
      this.fbAuth
    ).pipe(switchMap(() => of(undefined)));
  }

  public updateSlotInstructor(
    serviceId: number,
    from: string,
    to: string,
    payload: any
  ): Observable<void> {
    const user = this.fbAuth.currentUser;
    if (!user) {
      throw new Error("User must be signed in");
    }
    const endpoint = `${this.config.mdaCoreApiUrl}/v3/services/${serviceId}/schedule/from/${from}/to/${to}`;

    return makePostJsonRequest$<unknown>(
      endpoint,
      payload,
      t.unknown,
      false,
      this.fbAuth
    ).pipe(switchMap(() => of(undefined)));
  }

  public editSubscriptionExpirationDate(
    studentId: string,
    expirationDate: number
  ): Observable<void> {
    const endpoint = `${this.config.bonniersApi}/users/${studentId}/subscriptionExpirationDateAdmin/${expirationDate}`;
    return makePutJsonRequest$(
      endpoint,
      undefined,
      t.unknown,
      this.fbAuth,
      true
    ).pipe(switchMap(() => of(undefined)));
  }
}
