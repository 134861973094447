import { Form, Layout, Select } from "antd";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { EnglandFlagIcon } from "./englandFlagIcon";
import { SwedishFlagIcon } from "./swedishFlagIcon";
import styles from "../styles.css";
import { Language } from "../../../../i18n/i18n";
import moment from "moment";

export const handleLocaleChanges = (localeString: string) => {
  switch (localeString) {
    case Language.English:
      moment.locale("en-us");
      break;
    case Language.Swedish:
      moment.locale("sv");
      break;
    default:
      moment.locale("en-us");
  }
};

const LanguageSelector: FC = () => {
  const { i18n } = useTranslation();
  const defaultValue = localStorage.getItem("i18nextLng") || "";

  return (
    <div className={styles.select}>
      <Select
        defaultValue={defaultValue}
        onSelect={(_, res) => {
          i18n.changeLanguage(res.value);
          handleLocaleChanges(res.value);
        }}
      >
        <Select.Option value="sv">
          <SwedishFlagIcon width={28} height={20}></SwedishFlagIcon>
        </Select.Option>
        <Select.Option value="en">
          <EnglandFlagIcon width={28} height={20}></EnglandFlagIcon>
        </Select.Option>
      </Select>
    </div>
  );
};
export default LanguageSelector;
