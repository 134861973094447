// integer keys makes dictionary be an array; more other - empty array turns into nothing;
export type UnsafeFirebaseDictionary<T> = Readonly<
  { [key: number]: T } | { [key: string]: T } | readonly T[] | undefined
>;

export function dictFromUnsafeValue<T>(
  unsafeValue: UnsafeFirebaseDictionary<T>
): { [key: string]: T } {
  let res: { [key: number]: T };
  if (!unsafeValue) {
    res = {};
  } else if (Array.isArray(unsafeValue)) {
    res = {};
    unsafeValue.forEach((v, i) => {
      res[i] = v;
    });
  } else {
    res = unsafeValue;
  }

  return res;
}

export function toArray<T>(val: { [key: string]: T }): T[] {
  return Object.keys(val)
    .map(key => val[key])
    .filter(x => !!x);
}
